var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('validation-observer',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [(!_vm.isDataLoaded)?_c('b-card',[_c('div',{staticClass:"text-center text-success my-2"},[_c('b-spinner',{staticClass:"align-middle"})],1)]):_c('b-form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[_c('b-row',{staticClass:"align-items-end"},[_c('b-col',{attrs:{"cols":"6"}},[_c('LocaleTabs',{attrs:{"errors":_vm.formErrors,"name":"label"},scopedSlots:_vm._u([_vm._l((_vm.languages),function(i,k,idx){return {key:("lang" + idx),fn:function(slotScope){return [_c('ValidationProvider',{key:idx,class:slotScope.selectedTab!==idx?'hidden':'',attrs:{"vid":("label." + k),"name":_vm.$t('general.field_lang', { field: _vm.$t('labels.label'), lang: _vm.$t(("general." + (i.toLocaleLowerCase()))) }),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('general.field_lang', { field: _vm.$t('labels.label'), lang: _vm.$t(("general." + (i.toLocaleLowerCase()))) })}},[_c('b-form-input',{attrs:{"disabled":_vm.is_integration == 1,"placeholder":_vm.$t('general.field_lang', { field: _vm.$t('labels.label'), lang: _vm.$t(("general." + (i.toLocaleLowerCase()))) })},model:{value:(_vm.form.label[k]),callback:function ($$v) {_vm.$set(_vm.form.label, k, $$v)},expression:"form.label[k]"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})]}}})],null,true)})],1),_c('b-col',{attrs:{"cols":"6"}},[_c('LocaleTabs',{attrs:{"errors":_vm.formErrors,"name":"placeholder"},scopedSlots:_vm._u([_vm._l((_vm.languages),function(i,k,idx){return {key:("lang" + idx),fn:function(slotScope){return [_c('ValidationProvider',{key:idx,class:slotScope.selectedTab!==idx?'hidden':'',attrs:{"vid":("placeholder." + k),"name":_vm.$t('general.field_lang', { field: _vm.$t('labels.placeholder'), lang: _vm.$t(("general." + (i.toLocaleLowerCase()))) })},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('general.field_lang', { field: _vm.$t('labels.placeholder'), lang: _vm.$t(("general." + (i.toLocaleLowerCase()))) })}},[_c('b-form-input',{attrs:{"placeholder":_vm.$t('general.field_lang', { field: _vm.$t('labels.placeholder'), lang: _vm.$t(("general." + (i.toLocaleLowerCase()))) })},model:{value:(_vm.form.placeholder[k]),callback:function ($$v) {_vm.$set(_vm.form.placeholder, k, $$v)},expression:"form.placeholder[k]"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})]}}})],null,true)})],1),_c('b-col',{attrs:{"cols":"6"}},[_c('LocaleTabs',{attrs:{"errors":_vm.formErrors,"name":"icon"},scopedSlots:_vm._u([_vm._l((_vm.languages),function(i,k,idx){return {key:("lang" + idx),fn:function(slotScope){return [_c('ValidationProvider',{key:idx,class:slotScope.selectedTab!==idx?'hidden':'',attrs:{"vid":("icon." + k),"name":_vm.$t('general.field_lang', { field: _vm.$t('labels.icon'), lang: _vm.$t(("general." + (i.toLocaleLowerCase()))) })},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('general.field_lang', { field: _vm.$t('labels.icon'), lang: _vm.$t(("general." + (i.toLocaleLowerCase()))) })}},[_c('b-form-input',{attrs:{"placeholder":_vm.$t('general.field_lang', { field: _vm.$t('labels.icon'), lang: _vm.$t(("general." + (i.toLocaleLowerCase()))) })},model:{value:(_vm.form.icon[k]),callback:function ($$v) {_vm.$set(_vm.form.icon, k, $$v)},expression:"form.icon[k]"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})]}}})],null,true)})],1),_c('b-col',{attrs:{"cols":"6","xl":"6","md":"6"}},[_c('validation-provider',{attrs:{"vid":"type","name":_vm.$t('labels.type'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('labels.type')}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.layout.direction,"label":"value","options":_vm.fields_types,"reduce":function (item) { return item.key; },"placeholder":_vm.$t('labels.type')},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),(_vm.form.type == "list" || _vm.form.type == "checkbox" || _vm.form.type == "radio")?_c('b-col',{attrs:{"cols":"6","xl":"6","md":"6"}},[_c('validation-provider',{attrs:{"vid":"type","name":_vm.$t('labels.model_id'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('labels.model_id')}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.layout.direction,"label":"name","options":_vm.statuses,"reduce":function (item) { return item.id; },"placeholder":_vm.$t('labels.model_id')},model:{value:(_vm.form.model_id),callback:function ($$v) {_vm.$set(_vm.form, "model_id", $$v)},expression:"form.model_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,1116820461)})],1):_vm._e(),(_vm.form.type == "range")?_c('b-col',{attrs:{"md":"6","cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('min'),"vid":"min","rules":{
                       required: true,
                       numeric: true,
                     }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                     var errors = ref.errors;
return [_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.$t('min')))])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"placeholder":_vm.$t('min'),"autocomplete":"off","type":"number"},model:{value:(_vm.form.validation.min),callback:function ($$v) {_vm.$set(_vm.form.validation, "min", $$v)},expression:"form.validation.min"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,1336737379)})],1):_vm._e(),(_vm.form.type == "range")?_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"vid":"is_two_sided","name":_vm.$t('labels.is_two_sided'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                     var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('labels.is_two_sided')}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.layout.direction,"label":"name","options":_vm.rangeTypes,"reduce":function (item) { return item.id; },"placeholder":_vm.$t('labels.is_two_sided')},model:{value:(_vm.form.validation.is_two_sided),callback:function ($$v) {_vm.$set(_vm.form.validation, "is_two_sided", $$v)},expression:"form.validation.is_two_sided"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,675111818)})],1):_vm._e(),(_vm.form.type == "range")?_c('b-col',{attrs:{"md":"6","cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('max'),"vid":"max","rules":{
                       required: true,
                       numeric: true,
                     }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                     var errors = ref.errors;
return [_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.$t('max')))])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"placeholder":_vm.$t('max'),"autocomplete":"off","type":"number"},model:{value:(_vm.form.validation.max),callback:function ($$v) {_vm.$set(_vm.form.validation, "max", $$v)},expression:"form.validation.max"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,4011706493)})],1):_vm._e(),_c('b-col',{attrs:{"md":"6","cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('page_number'),"vid":"page_number","rules":{
                       numeric: true,
                     }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                     var errors = ref.errors;
return [_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.$t('page_number')))])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"placeholder":_vm.$t('page_number'),"autocomplete":"off","type":"number"},model:{value:(_vm.form.page_number),callback:function ($$v) {_vm.$set(_vm.form, "page_number", $$v)},expression:"form.page_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),(_vm.form.type == "between")?_c('b-col',{attrs:{"cols":"6"}},[_c('LocaleTabs',{attrs:{"errors":_vm.formErrors,"name":"placeholder_from"},scopedSlots:_vm._u([_vm._l((_vm.languages),function(i,k,idx){return {key:("lang" + idx),fn:function(slotScope){return [_c('ValidationProvider',{key:idx,class:slotScope.selectedTab!==idx?'hidden':'',attrs:{"vid":("placeholder_from." + k),"name":_vm.$t('general.field_lang', { field: _vm.$t('labels.placeholder_from'), lang: _vm.$t(("general." + (i.toLocaleLowerCase()))) }),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                     var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('general.field_lang', { field: _vm.$t('labels.placeholder_from'), lang: _vm.$t(("general." + (i.toLocaleLowerCase()))) })}},[_c('b-form-input',{attrs:{"placeholder":_vm.$t('general.field_lang', { field: _vm.$t('labels.placeholder_from'), lang: _vm.$t(("general." + (i.toLocaleLowerCase()))) })},model:{value:(_vm.form.placeholder_from[k]),callback:function ($$v) {_vm.$set(_vm.form.placeholder_from, k, $$v)},expression:"form.placeholder_from[k]"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})]}}})],null,true)})],1):_vm._e(),(_vm.form.type == "between")?_c('b-col',{attrs:{"cols":"6"}},[_c('LocaleTabs',{attrs:{"errors":_vm.formErrors,"name":"placeholder_to"},scopedSlots:_vm._u([_vm._l((_vm.languages),function(i,k,idx){return {key:("lang" + idx),fn:function(slotScope){return [_c('ValidationProvider',{key:idx,class:slotScope.selectedTab!==idx?'hidden':'',attrs:{"vid":("placeholder_to." + k),"name":_vm.$t('general.field_lang', { field: _vm.$t('labels.placeholder_to'), lang: _vm.$t(("general." + (i.toLocaleLowerCase()))) }),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                     var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('general.field_lang', { field: _vm.$t('labels.placeholder_to'), lang: _vm.$t(("general." + (i.toLocaleLowerCase()))) })}},[_c('b-form-input',{attrs:{"placeholder":_vm.$t('general.field_lang', { field: _vm.$t('labels.placeholder_to'), lang: _vm.$t(("general." + (i.toLocaleLowerCase()))) })},model:{value:(_vm.form.placeholder_to[k]),callback:function ($$v) {_vm.$set(_vm.form.placeholder_to, k, $$v)},expression:"form.placeholder_to[k]"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})]}}})],null,true)})],1):_vm._e(),(_vm.form.type == "checkbox")?_c('b-col',{attrs:{"cols":"6","xl":"6","md":"6"}},[_c('validation-provider',{attrs:{"vid":"size","name":_vm.$t('labels.size'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                     var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('labels.size')}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.layout.direction,"label":"name","options":_vm.sizes,"reduce":function (item) { return item.id; },"placeholder":_vm.$t('labels.size')},model:{value:(_vm.form.size),callback:function ($$v) {_vm.$set(_vm.form, "size", $$v)},expression:"form.size"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,2652096705)})],1):_vm._e(),_c('b-col',{attrs:{"md":"6","cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('sort'),"vid":"sort","rules":{
                       numeric: true,
                     }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                     var errors = ref.errors;
return [_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.$t('sort')))])]},proxy:true}],null,true)},[_c('p',{staticClass:"text-danger"},[_vm._v("ملاحظة: يتم ترتيب الحقول داخل الفئة")])])]}}])})],1),(_vm.is_integration != 1)?_c('b-col',{attrs:{"cols":"12"}},[_c('b-card-text',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t('labels.status'))+" ")]),_c('b-form-checkbox',{attrs:{"checked":"true","value":"1","unchecked-value":"0","name":"check-button","switch":""},model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}})],1):_vm._e(),_c('b-col',{staticClass:"mt-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"vid":"image","name":_vm.$t('labels.image'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                     var errors = ref.errors;
return [_c('ImagePreview',{attrs:{"accept-type":".png","label":_vm.$t('labels.image')},model:{value:(_vm.image),callback:function ($$v) {_vm.image=$$v},expression:"image"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('p',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.$t('image_png_note_and_dimension'))+" ")])],1),_c('b-col',{attrs:{"cols":"12 text-right"}},[_c('LoadingButton'),_c('b-button',{attrs:{"type":"reset","variant":"outline-secondary"}},[_vm._v(" "+_vm._s(_vm.$t('labels.reset'))+" ")])],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }