<template>
  <div>
    <b-card>
      <validation-observer
        ref='form'
        v-slot='{invalid}'
      >
        <b-card v-if='!isDataLoaded'>
          <div class='text-center text-success my-2'>
            <b-spinner class='align-middle' />
          </div>
        </b-card>
        <b-form
          v-else
          novalidate
          @submit.prevent='onSubmit'
        >
          <b-row class='align-items-end'>
            <b-col
              cols='12'
            >
              <LocaleTabs
                :errors='formErrors'
                name='title'
              >
                <template
                  v-for='(i,k,idx) in languages'
                  :slot='`lang${idx}`'
                  slot-scope='slotScope'
                >
                  <ValidationProvider
                    :key='idx'
                    v-slot='{ errors }'
                    :class="slotScope.selectedTab!==idx?'hidden':''"
                    :vid='`title.${k}`'
                    :name="$t('general.field_lang', { field: $t('labels.title'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                    rules='required'
                  >
                    <b-form-group
                      :label="$t('general.field_lang', { field: $t('labels.title'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                    >
                      <b-form-input
                        v-model='form.title[k]'
                        :disabled='is_integration == 1'
                        :placeholder="$t('general.field_lang', { field: $t('labels.title'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                      />
                      <small class='text-danger'>{{ errors[0] }}</small>
                    </b-form-group>
                  </ValidationProvider>
                </template>
              </LocaleTabs>
            </b-col>

            <b-col cols='12'>
              <LocaleTabs
                :errors='formErrors'
                name='description'
              >
                <template
                  v-for='(i,k,idx) in languages'
                  :slot='`lang${idx}`'
                  slot-scope='slotScope'
                >
                  <ValidationProvider
                    :key='idx'
                    v-slot='{ errors }'
                    :class="slotScope.selectedTab!==idx?'hidden':''"
                    :vid='`description.${k}`'
                    :name="$t('general.field_lang', { field: $t('labels.description'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                  >
                    <b-form-group
                      :label="$t('general.field_lang', { field: $t('labels.description'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                    >
                      <b-form-textarea
                        v-model='form.description[k]'
                        :placeholder="$t('general.field_lang', { field: $t('labels.description'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                      />
                      <small class='text-danger'>{{ errors[0] }}</small>
                    </b-form-group>
                  </ValidationProvider>
                </template>
              </LocaleTabs>
            </b-col>

            <b-col cols='12'>
              <ValidationProvider
                v-slot='{ errors }'
                vid='image'
                :name="$t('labels.image')"
                rules='required'
              >
                <ImagePreview
                  v-model='image'
                  :label="$t('labels.image')"
                />
                <small class='text-danger'>{{ errors[0] }}</small>
              </ValidationProvider>
              <hr class='my-1'>
            </b-col>
            <b-col cols='12'>
              <ValidationProvider
                v-slot='{ errors }'
                vid='icon'
                :name="$t('labels.icon')"
                rules='required'
              >
                <ImagePreview
                  v-model='icon'
                  :label="$t('labels.icon')"
                />
                <small class='text-danger'>{{ errors[0] }}</small>
              </ValidationProvider>
              <hr class='my-1'>
            </b-col>

            <b-col cols='12'>
              <ValidationProvider
                v-slot='{ errors }'
                vid='brand_image'
                :name="$t('labels.brand_image')"
              >
                <ImagePreview
                  v-model='brand_image'
                  :label="$t('labels.brand_image')"
                />
                <small class='text-danger'>{{ errors[0] }}</small>
              </ValidationProvider>
              <hr class='my-1'>
            </b-col>


            <b-col cols='12'>
              <ValidationProvider
                v-slot='{ errors }'
                vid='icon_home'
                :name="$t('labels.icon_home')"
              >
                <ImagePreview
                  v-model='icon_home'
                  :label="$t('labels.icon_home')"
                />
                <small class='text-danger'>{{ errors[0] }}</small>
              </ValidationProvider>
              <hr class='my-1'>
            </b-col>
            <b-col
              md='6'
              cols='12'
            >
              <validation-provider
                v-slot='{ errors }'
                :name="$t('sort')"
                vid='sort'
                :rules='{
                         numeric: true,
                       }'
              >
                <b-form-group>
                  <template #label>
                    <span>{{ $t('sort') }}</span>
                  </template>
                  <b-form-input
                    v-model='form.sort'
                    :placeholder="$t('sort')"
                    autocomplete='off'
                    type='number'
                  />
                  <small class='text-danger'>{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>


            <!-- submit and reset -->
            <b-col cols='12 text-right'>
              <LoadingButton />
              <b-button
                type='reset'
                variant='outline-secondary'
              >
                {{ $t('labels.reset') }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>
<script>
import formMixin from '@/mixins/formMixin'
import _ from 'lodash'

export default {
  mixins: [formMixin],
  data() {
    return {
      constants: [],
      image: null,
      icon: null,
      brand_image: null,
      icon_home: null,
      form: {
        title: {
          en: null,
          ar: null,
        },
        description: {
          en: null,
          ar: null,
        },
        sort: null,
        image: null,
        icon: null,
        brand_image: null,
        icon_home: null,
      },
      isDataLoaded: false,
      is_integration: 0,
    }
  },
  watch: {
    image(val) {
      if (val) {
        this.form.image = val
      } else {
        this.form.image = 'delete'
      }
    },
    icon(val) {
      if (val) {
        this.form.icon = val
      } else {
        this.form.icon = 'delete'
      }
    },
    brand_image(val) {
      if (val) {
        this.form.brand_image = val
      } else {
        this.form.brand_image = 'delete'
      }
    },

    icon_home(val) {
      if (val) {
        this.form.icon_home = val
      } else {
        this.form.icon_home = 'delete'
      }
    },
  },
  computed: {
    toRedirectResource() {
      return 'real-state-usage'
    },
  },
  created() {
    if (this.isEdit) {
      this.loadData()
    } else {
      this.isDataLoaded = true
    }
  },
  methods: {
    loadData() {
      this.axios.get(`/${this.resource}/${this.resourceId}`)
        .then(res => {
          const { data } = res.data
          this.form = {
            title: data.title,
            description: data?.description['ar'] ?? false ? data.description : { en: null, ar: null },
            sort: data.sort,
          }
          this.image = data.image_path
          this.icon = data.icon_path
          this.icon_home = data.icon_home_path
          this.is_integration = data.is_integration
          this.brand_image = data.brand_image_path
          this.isDataLoaded = true
        })
    },

    loadFormData() {
      let $form = _.cloneDeep(this.form)
      $form = {
        ...$form,
      }
      if (this.isEdit) {
        if (!($form.image instanceof File)) {
          delete $form.image
        }
        if (!($form.icon instanceof File)) {
          delete $form.icon
        }

        // if (!($form.brand_image instanceof File)) {
        //   delete $form.brand_image
        // }

        this.form_data = {
          ...$form,
          _method: 'put',
        }
      }
      return $form
    },

  },

}
</script>
