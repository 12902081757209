<template>
  <div>
    <b-card>
      <validation-observer
          ref='form'
          v-slot='{invalid}'
      >
        <b-card v-if='!isDataLoaded'>
          <div class='text-center text-success my-2'>
            <b-spinner class='align-middle' />
          </div>
        </b-card>
        <b-form
            v-else
            novalidate
            @submit.prevent='onSubmit'
        >
          <b-row>

            <!--            <b-col-->
            <!--              cols='12'-->
            <!--            >-->
            <!--              <validation-provider-->
            <!--                v-slot='{ errors }'-->
            <!--                :name="$t('name')"-->
            <!--                vid='name'-->
            <!--                rules='required'-->
            <!--              >-->
            <!--                <b-form-group-->
            <!--                  :label="$t('name')"-->
            <!--                >-->
            <!--                  <b-form-input-->
            <!--                    v-model='form.name'-->
            <!--                    :placeholder="$t('name')"-->
            <!--                    autocomplete='off'-->
            <!--                    type='text'-->
            <!--                  />-->
            <!--                  <small class='text-danger'>{{ errors[0] }}</small>-->
            <!--                </b-form-group>-->
            <!--              </validation-provider>-->
            <!--            </b-col>-->

            <!--            <b-col-->
            <!--              cols='12'-->
            <!--            >-->
            <!--              <validation-provider-->
            <!--                v-slot='{ errors }'-->
            <!--                :name="$t('description')"-->
            <!--                vid='description'-->
            <!--                rules='required'-->
            <!--              >-->
            <!--                <b-form-group-->
            <!--                  :label="$t('description')"-->
            <!--                >-->
            <!--                  <b-form-textarea-->
            <!--                    v-model='form.description'-->
            <!--                    :placeholder="$t('description')"-->
            <!--                    autocomplete='off'-->
            <!--                    rows='5'-->
            <!--                  />-->
            <!--                  <small class='text-danger'>{{ errors[0] }}</small>-->
            <!--                </b-form-group>-->
            <!--              </validation-provider>-->
            <!--            </b-col>-->


            <b-col
                cols='12'
                md='6'
            >
              <validation-provider
                  v-slot='{ errors }'
                  vid='types'
                  :name="$t('type')"
              >
                <b-form-group
                    :label="$t('type')"
                >
                  <v-select
                      v-model='form.type'
                      :dir='$store.state.appConfig.layout.direction'
                      label='name'
                      :options='types'
                      :reduce='item => item.id'
                      :placeholder="$t('select')"

                      @input="inputChange('type')"

                  >
                    <template #no-options>
                      {{ $t('No options') }}
                    </template>
                  </v-select>
                  <small class='text-danger'>{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>


            <b-col
                cols='12'
                md='6'
                v-if='form.type =="2"'
            >
              <validation-provider
                  v-slot='{ errors }'
                  vid='pages'
                  :name="$t('page')"
              >
                <b-form-group
                    :label="$t('page')"
                >
                  <v-select
                      v-model='form.link'
                      :dir='$store.state.appConfig.layout.direction'
                      label='name'
                      :options='pages'
                      :reduce='item => item.id'
                      :placeholder="$t('select')"
                      @input="inputChange('link')"
                  >
                    <template #no-options>
                      {{ $t('No options') }}
                    </template>
                  </v-select>
                  <small class='text-danger'>{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
                cols='12'
                md='6'
                v-if='show_page_list && form.type =="2"'
            >
              <validation-provider
                  v-slot='{ errors }'
                  vid='page_id'
                  :name="$t('page_required_id')"
                  rules='required'
              >
                <b-form-group
                    :label="$t('page_required_id')"
                >

                  <v-select
                      v-model='form.page_id'
                      :dir='$store.state.appConfig.layout.direction'
                      label='name'
                      :options='page_options'
                      :reduce='item => item.id'
                      :placeholder="$t('select')"
                  >
                    <template #no-options>
                      {{ $t('No options') }}
                    </template>
                  </v-select>
                  <small class='text-danger'>{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!--            <b-col-->
            <!--              cols='12'-->
            <!--              md='6'-->
            <!--              v-if='form.type =="2"'-->
            <!--            >-->
            <!--              <validation-provider-->
            <!--                v-slot='{ errors }'-->
            <!--                vid='link'-->
            <!--                :name="$t('link')"-->
            <!--                rules='required'-->
            <!--              >-->
            <!--                <b-form-group-->
            <!--                  :label="$t('link')"-->
            <!--                >-->
            <!--                  <v-select-->
            <!--                    v-model='form.link'-->
            <!--                    :dir='$store.state.appConfig.layout.direction'-->
            <!--                    label='name'-->
            <!--                    :options='pages'-->
            <!--                    :reduce='item => item.id'-->
            <!--                    :placeholder="$t('select')"-->
            <!--                  >-->
            <!--                    <template #no-options>-->
            <!--                      {{ $t('No options') }}-->
            <!--                    </template>-->
            <!--                  </v-select>-->
            <!--                  <small class='text-danger'>{{ errors[0] }}</small>-->
            <!--                </b-form-group>-->
            <!--              </validation-provider>-->
            <!--            </b-col>-->

            <b-col
                cols='12'
                md='6'
                v-if='form.type == "3"'
            >
              <validation-provider
                  v-slot='{ errors }'
                  :name="$t('link')"
                  vid='link'
                  rules='required'
              >
                <b-form-group
                    :label="$t('link')"
                >
                  <b-form-input
                      v-model='form.link'
                      :placeholder="$t('link')"
                      autocomplete='off'
                      type='text'
                  />
                  <small class='text-danger'>{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>


            <b-col cols='12'>
              <ValidationProvider
                  v-slot='{ errors }'
                  vid='image'
                  :name="$t('labels.image')"
              >
                <ImagePreview
                    v-model='image'
                    :label="$t('labels.image')"
                />
                <small class='text-danger'>{{ errors[0] }}</small>
              </ValidationProvider>
              <hr class='my-1'>
            </b-col>


            <!-- submit and reset -->
            <b-col cols='12 text-right'>
              <LoadingButton />
              <b-button
                  type='reset'
                  variant='outline-secondary'
              >
                {{ $t('labels.reset') }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>
<script>
import formMixin from '@/mixins/formMixin'
import { websiteMainUrl } from '@/services/url.service'

export default {
  mixins: [formMixin],
  data() {
    return {
      image: null,
      show_page_list: false,

      types: [{
        id: 1,
        name: this.$t('without_link'),
      },
        {
          id: 2,
          name: this.$t('internal_link'),
        },
        {
          id: 3,
          name: this.$t('external_link'),
        },
      ],
      page_options: [],
      pages: [
        {
          id: '/subscriptions',
          name: this.$t('packages'),
        },
        {
          id: '/investmentHubStories',
          name: this.$t('investment_hub_stories'),
        },
        {
          id: '/profile',
          name: this.$t('Profile'),
        },
        {
          id: '/projectDetails',
          name: this.$t('project_details'),
        },
        {
          id: '/developerDetails',
          name: this.$t('developer_details'),
        },
        {
          id: '/add_estate_options',
          name: this.$t('add_estate_options'),
        },
        {
          id: '/privateOffer',
          name: this.$t('private_offer'),
        },

        {
          id: '/request_estate_private',
          name: this.$t('request_estate_private'),
        },
        {
          id: '/request_estate',
          name: this.$t('request_estate'),
        },
      ],

      form: {
        type: null,
        // name: null,
        // description: null,
        image: null,
        link: null,
        page_id: null,
      },

      isDataLoaded: false,
    }
  },
  created() {

    if (this.isEdit) {
      this.loadData()
    } else {
      this.isDataLoaded = true
    }
  },
  watch: {
    image(val) {
      if (val) {
        this.form.image = val
      } else {
        this.form.image = 'delete'
      }
    },


  },

  methods: {
    inputChange(type) {
      console.log(type)
      if (type == 'type') {
        this.show_page_list = false
        this.form.link = null
        this.form.page_id = null
      }

      if (type == 'link') {
        this.show_page_list = false
        this.form.page_id = null

        if (this.form.link == '/projectDetails') {
          this.getPageOptions('projects')
        }
        if (this.form.link == '/developerDetails') {
          this.getPageOptions('developers')
        }
        if (this.form.link == '/investmentHubStories') {
          this.getPageOptions('opportunities')
        }

      }

    },

    loadData() {
      this.axios.get(`/${this.resource}/${this.resourceId}`)
          .then(res => {
            const { data } = res.data
            this.form = {
              type: data.type,
              link: data.link,
              page_id: data.page_id,
            }
            this.image = data.image_path


            if (this.form.link == '/projectDetails') {
              this.getPageOptions('projects', true)
            }
            if (this.form.link == '/developerDetails') {
              this.getPageOptions('developers',true)

            }
            if (this.form.link == '/investmentHubStories') {
              this.getPageOptions('opportunities',true)
            }


            this.isDataLoaded = true
          })
    },

    getPageOptions(type, isFromLoad = false) {
      this.show_page_list = true

      this.axios.get(`/model-list/${type}`)
          .then(res => {
            if (!isFromLoad) {
              this.form.page_id = null
            }

            this.page_options = res.data.data
          })
    },

  },

}
</script>
