<template>
  <div>
    <b-card no-body>
      <b-card-body>
        <b-row>
          <b-col
            cols='12'
            md='4'
            class='mb-md-0 mb-2'
          >
            <b-form-group
              :label="$t('labels.name')"
              label-for='name'
            >
              <b-form-input
                id='name'
                :value='searchFields.name'
                @input="inputChange($event, 'name')"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols='12'
            md='4'
            class='mb-md-0 mb-2'
          >
            <b-form-group
              :label="$t('labels.mobile')"
              label-for='mobile'
            >
              <b-form-input
                id='name'
                type='number'
                :value='searchFields.mobile'
                @input="inputChange($event, 'mobile')"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols='12'
            md='4'
            class='mb-md-0 mb-2'
          >
            <b-form-group
              :label="$t('labels.email')"
              label-for='email'
            >
              <b-form-input
                id='name'
                :value='searchFields.email'
                @input="inputChange($event, 'email')"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols='12'
            md='4'
            class='mb-md-0 mb-2'
          >
            <label>{{ $t('labels.status') }}</label>
            <v-select
              v-model='searchFields.status'
              :dir='$store.state.appConfig.layout.direction'
              :options='statuses'
              :reduce='item => item.id'
              label='name'
              class='w-100'
            >
              <template #no-options>{{ $t('No options') }}</template>
            </v-select>
          </b-col>
          <b-col
            cols='12'
            md='4'
            class='mb-md-0 mb-2'
          >
            <label>{{ $t('unified_national_nafath_authentication_status') }}</label>
            <v-select
              v-model='searchFields.unified_national_nafath_authentication_status'
              :dir='$store.state.appConfig.layout.direction'
              :options='nafath'
              :reduce='item => item.id'
              label='name'
              class='w-100'
            >
              <template #no-options>{{ $t('No options') }}</template>
            </v-select>
          </b-col>

          <b-col
            cols='12'
            md='4'
            class='mb-md-0 mb-2'
          >
            <label>{{ $t('fal_status') }}</label>
            <v-select
              v-model='searchFields.fal_status'
              :dir='$store.state.appConfig.layout.direction'
              :options='falList'
              :reduce='item => item.id'
              label='name'
              class='w-100'
            >
              <template #no-options>{{ $t('No options') }}</template>
            </v-select>
          </b-col>

        </b-row>
      </b-card-body>
    </b-card>
    <TableIndex
      :resource-name='resourceName'
      :singular-name='singularName'
      :filter-fields='searchFields'
      :have-dropdown-actions='true'
      :permissionKey='"customer"'
      :isExport='true'
    >
      <!--      <template #table-header-link>-->
      <!--        <router-link-->
      <!--          :to="{ name: 'comparisons-logs' }"-->
      <!--          class="btn btn-primary"-->
      <!--        >-->
      <!--          <span-->
      <!--            class="text-capitalize"-->
      <!--            role="link"-->
      <!--          >{{ $t('comparison logs') }}</span>-->
      <!--        </router-link>-->
      <!--      </template>-->
      <template v-slot:dropdown_actions='{ item, generalActions }'>

        <b-dropdown-item
          :to="{ name: 'payment-customer', params: { resourceId: item.id } }"
        >
          <feather-icon
            icon='EyeIcon'
            class='mr-50'
          />
          <span>{{ $t('payment-customer') }}</span>
        </b-dropdown-item>

        <!--        <b-dropdown-item-->
<!--          :to="{ name: 'customer-search', params: { customer_id: item.id } }"-->
<!--        >-->
<!--          <feather-icon-->
<!--            icon='EyeIcon'-->
<!--            class='mr-50'-->
<!--          />-->
<!--          <span>{{ $t('Search Archive') }}</span>-->
<!--        </b-dropdown-item>-->
<!--        <b-dropdown-item-->
<!--          :to="{ name: 'customer-views', params: { customer_id: item.id } }"-->
<!--        >-->
<!--          <feather-icon-->
<!--            icon='EyeIcon'-->
<!--            class='mr-50'-->
<!--          />-->
<!--          <span>{{ $t('Views Archive') }}</span>-->
<!--        </b-dropdown-item>-->
<!--        <b-dropdown-item-->
<!--          :to="{ name: 'favorite-ads', params: { customer_id: item.id } }"-->
<!--        >-->
<!--          <feather-icon-->
<!--            icon='StarIcon'-->
<!--            class='mr-50'-->
<!--          />-->
<!--          <span>{{ $t('Favorite Ads') }}</span>-->
<!--        </b-dropdown-item>-->
        <!--        <b-dropdown-item-->
        <!--          :to="{ name: 'advertiser-ratings', params: { customer_id: item.id } }"-->
        <!--        >-->
        <!--          <feather-icon-->
        <!--            icon="BarChartIcon"-->
        <!--            class="mr-50"-->
        <!--          />-->
        <!--          <span>{{ $t('Advertiser Ratings') }}</span>-->
        <!--        </b-dropdown-item>-->
      </template>
    </TableIndex>
  </div>
</template>

<script>
import listMixin from '@/mixins/listMixin'

export default {
  mixins: [listMixin],
  data() {
    return {
      resourceName: 'customers',
      singularName: 'customer',
      searchFields: {
        id: null,
        name: null,
        // member_no: null,
        status: null,
        mobile: null,
        email: null,
        unified_national_nafath_authentication_status: null,
        fal_status: null,
      },
    }
  },
  methods: {
    // eslint-disable-next-line func-names
    inputChange: _.debounce(function(e, field) {
      this.searchFields[field] = e
    }, 500),
  },
  // eslint-disable-next-line vue/order-in-components
  computed: {
    statuses() {
      return [
        {
          id: 1,
          name: this.$t('statuses.active'),
        },
        {
          id: 0,
          name: this.$t('statuses.inactive'),
        },
        // {
        //   id: 2,
        //   name: this.$t('statuses.not_verified'),
        // },
      ]
    },
    falList() {
      return [
        {
          id: 0,
          name: this.$t('not_licensed'),
        },
        {
          id: 1,
          name: this.$t('under_studying'),
        },
        {
          id: 2,
          name: this.$t('licensed'),
        },
      ]
    },

    nafath() {
      return [
        {
          id: 0,
          name: this.$t('no'),
        },
        {
          id: 1,
          name: this.$t('yes'),
        },
        // {
        //   id: 2,
        //   name: this.$t('statuses.not_verified'),
        // },
      ]
    },

  },
}
</script>
