<template>
  <div>
    <b-card>
      <validation-observer
        ref='form'
        v-slot='{invalid}'
      >
        <b-card v-if='!isDataLoaded'>
          <div class='text-center text-success my-2'>
            <b-spinner class='align-middle' />
          </div>
        </b-card>
        <b-form
          v-else
          novalidate
          @submit.prevent='onSubmit'
        >
          <b-row class='align-items-end'>
            <b-col
              cols='6'
            >
              <LocaleTabs
                :errors='formErrors'
                name='label'
              >
                <template
                  v-for='(i,k,idx) in languages'
                  :slot='`lang${idx}`'
                  slot-scope='slotScope'
                >
                  <ValidationProvider
                    :key='idx'
                    v-slot='{ errors }'
                    :class="slotScope.selectedTab!==idx?'hidden':''"
                    :vid='`label.${k}`'
                    :name="$t('general.field_lang', { field: $t('labels.label'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                    rules='required'
                  >
                    <b-form-group
                      :label="$t('general.field_lang', { field: $t('labels.label'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                    >
                      <b-form-input
                        v-model='form.label[k]'
                        :disabled='is_integration == 1'
                        :placeholder="$t('general.field_lang', { field: $t('labels.label'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                      />
                      <small class='text-danger'>{{ errors[0] }}</small>
                    </b-form-group>
                  </ValidationProvider>
                </template>
              </LocaleTabs>
            </b-col>
            <b-col
              cols='6'

            >
              <LocaleTabs
                :errors='formErrors'
                name='placeholder'
              >
                <template
                  v-for='(i,k,idx) in languages'
                  :slot='`lang${idx}`'
                  slot-scope='slotScope'
                >
                  <ValidationProvider
                    :key='idx'
                    v-slot='{ errors }'
                    :class="slotScope.selectedTab!==idx?'hidden':''"
                    :vid='`placeholder.${k}`'
                    :name="$t('general.field_lang', { field: $t('labels.placeholder'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                  >
                    <b-form-group
                      :label="$t('general.field_lang', { field: $t('labels.placeholder'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                    >
                      <b-form-input
                        v-model='form.placeholder[k]'
                        :placeholder="$t('general.field_lang', { field: $t('labels.placeholder'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                      />
                      <small class='text-danger'>{{ errors[0] }}</small>
                    </b-form-group>
                  </ValidationProvider>
                </template>
              </LocaleTabs>
            </b-col>
            <b-col
              cols='6'
            >
              <LocaleTabs
                :errors='formErrors'
                name='icon'
              >
                <template
                  v-for='(i,k,idx) in languages'
                  :slot='`lang${idx}`'
                  slot-scope='slotScope'
                >
                  <ValidationProvider
                    :key='idx'
                    v-slot='{ errors }'
                    :class="slotScope.selectedTab!==idx?'hidden':''"
                    :vid='`icon.${k}`'
                    :name="$t('general.field_lang', { field: $t('labels.icon'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                  >
                    <b-form-group
                      :label="$t('general.field_lang', { field: $t('labels.icon'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                    >
                      <b-form-input
                        v-model='form.icon[k]'
                        :placeholder="$t('general.field_lang', { field: $t('labels.icon'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                      />
                      <small class='text-danger'>{{ errors[0] }}</small>
                    </b-form-group>
                  </ValidationProvider>
                </template>
              </LocaleTabs>
            </b-col>

            <b-col
              cols='6'
              xl='6'
              md='6'
            >
              <validation-provider
                v-slot='{ errors }'
                vid='type'
                :name="$t('labels.type')"
                rules='required'
              >
                <b-form-group
                  :label="$t('labels.type')"
                >
                  <v-select
                    v-model='form.type'
                    :dir='$store.state.appConfig.layout.direction'
                    label='value'
                    :options='fields_types'
                    :reduce='item => item.key'
                    :placeholder="$t('labels.type')"
                  />
                  <small class='text-danger'>{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>


            <b-col
              cols='6'
              xl='6'
              md='6'
              v-if='form.type == "list" || form.type == "checkbox" || form.type == "radio"'
            >
              <validation-provider
                v-slot='{ errors }'
                vid='type'
                :name="$t('labels.model_id')"
                rules='required'
              >
                <b-form-group
                  :label="$t('labels.model_id')"
                >
                  <v-select
                    v-model='form.model_id'
                    :dir='$store.state.appConfig.layout.direction'
                    label='name'
                    :options='statuses'
                    :reduce='item => item.id'
                    :placeholder="$t('labels.model_id')"
                  />
                  <small class='text-danger'>{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!--            <b-col-->
            <!--              md='6'-->
            <!--              cols='12'-->
            <!--              v-if='form.type == "range"'-->

            <!--            >-->
            <!--              <validation-provider-->
            <!--                v-slot='{ errors }'-->
            <!--                :name="$t('degree')"-->
            <!--                vid='value'-->
            <!--                :rules='{-->
            <!--                         required: true,-->
            <!--                         numeric: true,-->
            <!--                       }'-->
            <!--              >-->
            <!--                <b-form-group>-->
            <!--                  <template #label>-->
            <!--                    <span>{{ $t('degree') }}</span>-->
            <!--                  </template>-->
            <!--                  <b-form-input-->
            <!--                    v-model='form.value'-->
            <!--                    :placeholder="$t('degree')"-->
            <!--                    autocomplete='off'-->
            <!--                    type='number'-->
            <!--                  />-->
            <!--                  <small class='text-danger'>{{ errors[0] }}</small>-->
            <!--                </b-form-group>-->
            <!--              </validation-provider>-->
            <!--            </b-col>-->

            <b-col
              md='6'
              cols='12'
              v-if='form.type == "range"'

            >
              <validation-provider
                v-slot='{ errors }'
                :name="$t('min')"
                vid='min'
                :rules='{
                         required: true,
                         numeric: true,
                       }'
              >
                <b-form-group>
                  <template #label>
                    <span>{{ $t('min') }}</span>
                  </template>
                  <b-form-input
                    v-model='form.validation.min'
                    :placeholder="$t('min')"
                    autocomplete='off'
                    type='number'
                  />
                  <small class='text-danger'>{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              v-if='form.type == "range"'
              cols='12'
              md='6'
            >
              <validation-provider
                v-slot='{ errors }'
                vid='is_two_sided'
                :name="$t('labels.is_two_sided')"
                rules='required'
              >
                <b-form-group
                  :label="$t('labels.is_two_sided')"
                >
                  <v-select
                    v-model='form.validation.is_two_sided'
                    :dir='$store.state.appConfig.layout.direction'
                    label='name'
                    :options='rangeTypes'
                    :reduce='item => item.id'
                    :placeholder="$t('labels.is_two_sided')"
                  />
                  <small class='text-danger'>{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              md='6'
              cols='12'
              v-if='form.type == "range"'

            >
              <validation-provider
                v-slot='{ errors }'
                :name="$t('max')"
                vid='max'
                :rules='{
                         required: true,
                         numeric: true,
                       }'
              >
                <b-form-group>
                  <template #label>
                    <span>{{ $t('max') }}</span>
                  </template>
                  <b-form-input
                    v-model='form.validation.max'
                    :placeholder="$t('max')"
                    autocomplete='off'
                    type='number'
                  />
                  <small class='text-danger'>{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              md='6'
              cols='12'
            >
              <validation-provider
                v-slot='{ errors }'
                :name="$t('page_number')"
                vid='page_number'
                :rules='{
                         numeric: true,
                       }'
              >
                <b-form-group>
                  <template #label>
                    <span>{{ $t('page_number') }}</span>
                  </template>
                  <b-form-input
                    v-model='form.page_number'
                    :placeholder="$t('page_number')"
                    autocomplete='off'
                    type='number'
                  />
                  <small class='text-danger'>{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              cols='6'
              v-if='form.type == "between"'

            >
              <LocaleTabs
                :errors='formErrors'
                name='placeholder_from'
              >
                <template
                  v-for='(i,k,idx) in languages'
                  :slot='`lang${idx}`'
                  slot-scope='slotScope'
                >
                  <ValidationProvider
                    :key='idx'
                    v-slot='{ errors }'
                    :class="slotScope.selectedTab!==idx?'hidden':''"
                    :vid='`placeholder_from.${k}`'
                    :name="$t('general.field_lang', { field: $t('labels.placeholder_from'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                    rules='required'

                  >
                    <b-form-group
                      :label="$t('general.field_lang', { field: $t('labels.placeholder_from'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                    >
                      <b-form-input
                        v-model='form.placeholder_from[k]'
                        :placeholder="$t('general.field_lang', { field: $t('labels.placeholder_from'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                      />
                      <small class='text-danger'>{{ errors[0] }}</small>
                    </b-form-group>
                  </ValidationProvider>
                </template>
              </LocaleTabs>
            </b-col>

            <b-col
              cols='6'
              v-if='form.type == "between"'

            >
              <LocaleTabs
                :errors='formErrors'
                name='placeholder_to'
              >
                <template
                  v-for='(i,k,idx) in languages'
                  :slot='`lang${idx}`'
                  slot-scope='slotScope'
                >
                  <ValidationProvider
                    :key='idx'
                    v-slot='{ errors }'
                    :class="slotScope.selectedTab!==idx?'hidden':''"
                    :vid='`placeholder_to.${k}`'
                    :name="$t('general.field_lang', { field: $t('labels.placeholder_to'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                    rules='required'

                  >
                    <b-form-group
                      :label="$t('general.field_lang', { field: $t('labels.placeholder_to'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                    >
                      <b-form-input
                        v-model='form.placeholder_to[k]'
                        :placeholder="$t('general.field_lang', { field: $t('labels.placeholder_to'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                      />
                      <small class='text-danger'>{{ errors[0] }}</small>
                    </b-form-group>
                  </ValidationProvider>
                </template>
              </LocaleTabs>
            </b-col>

            <b-col
              cols='6'
              xl='6'
              md='6'
              v-if='form.type == "checkbox"'
            >
              <validation-provider
                v-slot='{ errors }'
                vid='size'
                :name="$t('labels.size')"
                rules='required'
              >
                <b-form-group
                  :label="$t('labels.size')"
                >
                  <v-select
                    v-model='form.size'
                    :dir='$store.state.appConfig.layout.direction'
                    label='name'
                    :options='sizes'
                    :reduce='item => item.id'
                    :placeholder="$t('labels.size')"
                  />
                  <small class='text-danger'>{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              md='6'
              cols='12'
            >
              <validation-provider
                v-slot='{ errors }'
                :name="$t('sort')"
                vid='sort'
                :rules='{
                         numeric: true,
                       }'
              >
                <b-form-group>
                  <template #label>
                    <span>{{ $t('sort') }}</span>
                  </template>
                  <p class='text-danger'>ملاحظة: يتم ترتيب الحقول داخل الفئة</p>
                </b-form-group>
              </validation-provider>
            </b-col>


            <!-- checkbox -->
            <b-col cols='12' v-if='is_integration != 1'>
              <b-card-text class='mb-0'>
                {{ $t('labels.status') }}
              </b-card-text>
              <b-form-checkbox
                v-model='form.status'
                checked='true'
                value='1'
                unchecked-value='0'
                name='check-button'
                switch
              />
            </b-col>

            <b-col cols='12' class='mt-1'>
              <ValidationProvider
                v-slot='{ errors }'
                vid='image'
                :name="$t('labels.image')"
                rules='required'
              >
                <ImagePreview
                  v-model='image'
                  :accept-type='".png"'
                  :label="$t('labels.image')"
                />
                <small class='text-danger'>{{ errors[0] }}</small>
              </ValidationProvider>

              <p class='text-danger'> {{ $t('image_png_note_and_dimension') }} </p>
            </b-col>


            <!-- submit and reset -->
            <b-col cols='12 text-right'>
              <LoadingButton />
              <b-button
                type='reset'
                variant='outline-secondary'
              >
                {{ $t('labels.reset') }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>
<script>
import formMixin from '@/mixins/formMixin'
import { websiteMainUrl } from '@/services/url.service'
import _ from 'lodash'

export default {
  mixins: [formMixin],


  data() {
    return {
      fields_types: [],
      image: null,
      form: {
        label: {
          en: null,
          ar: null,
        },
        placeholder: {
          en: null,
          ar: null,
        },
        placeholder_from: {
          en: null,
          ar: null,
        },
        placeholder_to: {
          en: null,
          ar: null,
        },
        icon: {
          en: null,
          ar: null,
        },
        validation: {
          max: null,
          min: null,
          is_two_sided: null,
        },
        value: null,
        model_id: null,
        type: null,
        size: null,
        status: 1,
        sort: null,
        page_number: null,
        range_type: null,
        image: null,
      },
      isDataLoaded: false,
      statuses: [],
      is_integration: 0,
      sizes: [
        {
          id: 1,
          name: this.$t('sizes.sm'),
        },
        {
          id: 2,
          name: this.$t('sizes.lg'),
        },
      ],
      rangeTypes: [
        { id: '0', name: this.$t('rangeTypes.0') },
        { id: '1', name: this.$t('rangeTypes.1') },
      ],
    }
  },

  watch: {
    image(val) {
      if (val) {
        this.form.image = val
      } else {
        this.form.image = 'delete'
      }
    },
    'form.type': {
      handler(val) {
        if (val != 'list' && val != 'radio' && val != 'checkbox') {
          this.form.model_id = null
        }

        if (val != 'range') {
          this.form.value = null
          this.form.validation = {
            max: null,
            min: null,
          }
        }

        if (val != 'checkbox') {
          this.form.size = null
        }

        if (val != 'between') {
          this.form.placeholder_from = {
            en: null,
            ar: null,
          }
          this.form.placeholder_to = {
            en: null,
            ar: null,
          }
        }
      },
    },
  },
  created() {
    this.getConstants()
    if (this.isEdit) {
      this.loadData()
    } else {
      this.isDataLoaded = true
    }
  },
  methods: {
    loadData() {
      this.axios.get(`/${this.resource}/${this.resourceId}`)
        .then(res => {
          const { data } = res.data
          this.form = {
            label: data.label,
            placeholder: data?.placeholder['ar'] ?? false ? data.placeholder : { en: null, ar: null },
            icon: data?.icon['ar'] ?? false ? data.icon : { en: null, ar: null },
            status: data.status,
            model_id: data.model_id,
            type: data.type,
            sort: data.sort,
            value: data.value,
            size: data.size,
            validation: data.validation,
            placeholder_from: data?.placeholder_from['ar'] ?? false ? data.placeholder_from : { en: null, ar: null },
            placeholder_to: data?.placeholder_to['ar'] ?? false ? data.placeholder_to : { en: null, ar: null },
            page_number: data.page_number,
          }

          this.is_integration = data.is_integration

          this.image = data.image
          this.isDataLoaded = true
        })
    },
    getConstants() {
      this.axios.get(`/real-estate/constants`)
        .then(res => {
          this.fields_types = res.data.data.fields_types
        })

      this.axios.get(`/model-options/type-parent-category`)
        .then(res => {
          this.statuses = res.data.data
        })
    },
    loadFormData() {
      let $form = _.cloneDeep(this.form)
      $form = {
        ...$form,
      }
      if (this.isEdit) {
        if (!($form.image instanceof File)) {
          delete $form.image
        }
        this.form_data = {
          ...$form,
          _method: 'put',
        }
      }
      return $form
    },

  },

}
</script>
