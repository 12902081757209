<template>
  <div>
    <b-card>
      <validation-observer
        ref='form'
        v-slot='{invalid}'
      >
        <b-card v-if='!isDataLoaded'>
          <div class='text-center text-success my-2'>
            <b-spinner class='align-middle' />
          </div>
        </b-card>
        <b-form
          v-else
          novalidate
          @submit.prevent='onSubmit'
        >
          <b-row class='align-items-end'>

            <b-col
              cols='12'
            >
              <LocaleTabs
                :errors='formErrors'
                name='banner_title'
              >
                <template
                  v-for='(i,k,idx) in languages'
                  :slot='`lang${idx}`'
                  slot-scope='slotScope'
                >
                  <ValidationProvider
                    :key='idx'
                    v-slot='{ errors }'
                    :class="slotScope.selectedTab!==idx?'hidden':''"
                    :vid='`banner_title.${k}`'
                    :name="$t('general.field_lang', { field: $t('labels.banner_title'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                    rules='required'
                  >
                    <b-form-group
                      :label="$t('general.field_lang', { field: $t('labels.banner_title'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                    >
                      <b-form-input
                        v-model='form.banner_title[k]'
                        :placeholder="$t('general.field_lang', { field: $t('labels.banner_title'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                      />
                      <small class='text-danger'>{{ errors[0] }}</small>
                    </b-form-group>
                  </ValidationProvider>
                </template>
              </LocaleTabs>
            </b-col>

            <b-col
              md='6'
              cols='12'>
              <LocaleTabs
                :errors='formErrors'
                name='first_label_value'
              >
                <template
                  v-for='(i,k,idx) in languages'
                  :slot='`lang${idx}`'
                  slot-scope='slotScope'
                >
                  <ValidationProvider
                    :key='idx'
                    v-slot='{ errors }'
                    :class="slotScope.selectedTab!==idx?'hidden':''"
                    :vid='`first_label_value.${k}`'
                    :name="$t('general.field_lang', { field: $t('labels.first_label_value'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                    rules='required'
                  >
                    <b-form-group
                      :label="$t('general.field_lang', { field: $t('labels.first_label_value'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                    >
                      <b-form-input
                        v-model='form.first_label_value[k]'
                        :placeholder="$t('general.field_lang', { field: $t('labels.first_label_value'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                      />
                      <small class='text-danger'>{{ errors[0] }}</small>
                    </b-form-group>
                  </ValidationProvider>
                </template>
              </LocaleTabs>
            </b-col>



            <b-col
              md='6'
              cols='12'>
              <LocaleTabs
                :errors='formErrors'
                name='first_value'
              >
                <template
                  v-for='(i,k,idx) in languages'
                  :slot='`lang${idx}`'
                  slot-scope='slotScope'
                >
                  <ValidationProvider
                    :key='idx'
                    v-slot='{ errors }'
                    :class="slotScope.selectedTab!==idx?'hidden':''"
                    :vid='`first_value.${k}`'
                    :name="$t('general.field_lang', { field: $t('labels.first_value'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                    rules='required'
                  >
                    <b-form-group
                      :label="$t('general.field_lang', { field: $t('labels.first_value'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                    >
                      <b-form-input
                        v-model='form.first_value[k]'
                        :placeholder="$t('general.field_lang', { field: $t('labels.first_value'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                      />
                      <small class='text-danger'>{{ errors[0] }}</small>
                    </b-form-group>
                  </ValidationProvider>
                </template>
              </LocaleTabs>
            </b-col>


            <b-col
              md='6'
              cols='12'>
              <LocaleTabs
                :errors='formErrors'
                name='second_label_value'
              >
                <template
                  v-for='(i,k,idx) in languages'
                  :slot='`lang${idx}`'
                  slot-scope='slotScope'
                >
                  <ValidationProvider
                    :key='idx'
                    v-slot='{ errors }'
                    :class="slotScope.selectedTab!==idx?'hidden':''"
                    :vid='`second_label_value.${k}`'
                    :name="$t('general.field_lang', { field: $t('labels.second_label_value'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                    rules='required'
                  >
                    <b-form-group
                      :label="$t('general.field_lang', { field: $t('labels.second_label_value'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                    >
                      <b-form-input
                        v-model='form.second_label_value[k]'
                        :placeholder="$t('general.field_lang', { field: $t('labels.second_label_value'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                      />
                      <small class='text-danger'>{{ errors[0] }}</small>
                    </b-form-group>
                  </ValidationProvider>
                </template>
              </LocaleTabs>
            </b-col>

            <b-col
              md='6'
              cols='12'>
              <LocaleTabs
                :errors='formErrors'
                name='second_value'
              >
                <template
                  v-for='(i,k,idx) in languages'
                  :slot='`lang${idx}`'
                  slot-scope='slotScope'
                >
                  <ValidationProvider
                    :key='idx'
                    v-slot='{ errors }'
                    :class="slotScope.selectedTab!==idx?'hidden':''"
                    :vid='`second_value.${k}`'
                    :name="$t('general.field_lang', { field: $t('labels.second_value'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                    rules='required'
                  >
                    <b-form-group
                      :label="$t('general.field_lang', { field: $t('labels.second_value'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                    >
                      <b-form-input
                        v-model='form.second_value[k]'
                        :placeholder="$t('general.field_lang', { field: $t('labels.second_value'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                      />
                      <small class='text-danger'>{{ errors[0] }}</small>
                    </b-form-group>
                  </ValidationProvider>
                </template>
              </LocaleTabs>
            </b-col>

            <b-col
              cols='12'
              md='3'
            >

              <validation-provider
                v-slot='{ errors }'
                vid='expired_date'
                :name="$t('expired_date')"
                rules='required'
               >
                <label>{{ $t('expired_date_opportunity') }}</label>
                <DatePicker
                  :reset='true'
                  v-model='form.expired_date'
                />

                <small class='text-danger'>{{ errors[0] }}</small>

              </validation-provider>
            </b-col>

            <b-col
              cols='12'
            >
              <LocaleTabs
                :errors='formErrors'
                name='first_step_title'
              >
                <template
                  v-for='(i,k,idx) in languages'
                  :slot='`lang${idx}`'
                  slot-scope='slotScope'
                >
                  <ValidationProvider
                    :key='idx'
                    v-slot='{ errors }'
                    :class="slotScope.selectedTab!==idx?'hidden':''"
                    :vid='`first_step_title.${k}`'
                    :name="$t('general.field_lang', { field: $t('labels.first_step_title'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                    rules='required'
                  >
                    <b-form-group
                      :label="$t('general.field_lang', { field: $t('labels.first_step_title'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                    >
                      <b-form-input
                        v-model='form.first_step_title[k]'
                        :placeholder="$t('general.field_lang', { field: $t('labels.first_step_title'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                      />
                      <small class='text-danger'>{{ errors[0] }}</small>
                    </b-form-group>
                  </ValidationProvider>
                </template>
              </LocaleTabs>
            </b-col>

            <b-col cols='12'>
              <LocaleTabs
                :errors='formErrors'
                name='first_step_description'
              >
                <template
                  v-for='(i,k,idx) in languages'
                  :slot='`lang${idx}`'
                  slot-scope='slotScope'
                >
                  <ValidationProvider
                    :key='idx'
                    v-slot='{ errors }'
                    :class="slotScope.selectedTab!==idx?'hidden':''"
                    :vid='`first_step_description.${k}`'
                    :name="$t('general.field_lang', { field: $t('labels.first_step_description'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                    rules='required'

                  >
                    <b-form-group
                      :label="$t('general.field_lang', { field: $t('labels.first_step_description'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                    >
                      <b-form-textarea
                        v-model='form.first_step_description[k]'
                        :placeholder="$t('general.field_lang', { field: $t('labels.first_step_description'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                      />
                      <small class='text-danger'>{{ errors[0] }}</small>
                    </b-form-group>
                  </ValidationProvider>
                </template>
              </LocaleTabs>
            </b-col>

            <b-col
              cols='12'
            >
              <LocaleTabs
                :errors='formErrors'
                name='second_step_title'
              >
                <template
                  v-for='(i,k,idx) in languages'
                  :slot='`lang${idx}`'
                  slot-scope='slotScope'
                >
                  <ValidationProvider
                    :key='idx'
                    v-slot='{ errors }'
                    :class="slotScope.selectedTab!==idx?'hidden':''"
                    :vid='`second_step_title.${k}`'
                    :name="$t('general.field_lang', { field: $t('labels.second_step_title'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                    rules='required'
                  >
                    <b-form-group
                      :label="$t('general.field_lang', { field: $t('labels.second_step_title'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                    >
                      <b-form-input
                        v-model='form.second_step_title[k]'
                        :placeholder="$t('general.field_lang', { field: $t('labels.second_step_title'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                      />
                      <small class='text-danger'>{{ errors[0] }}</small>
                    </b-form-group>
                  </ValidationProvider>
                </template>
              </LocaleTabs>
            </b-col>

            <b-col cols='12'>
              <LocaleTabs
                :errors='formErrors'
                name='second_step_description'
              >
                <template
                  v-for='(i,k,idx) in languages'
                  :slot='`lang${idx}`'
                  slot-scope='slotScope'
                >
                  <ValidationProvider
                    :key='idx'
                    v-slot='{ errors }'
                    :class="slotScope.selectedTab!==idx?'hidden':''"
                    :vid='`second_step_description.${k}`'
                    :name="$t('general.field_lang', { field: $t('labels.second_step_description'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                    rules='required'

                  >
                    <b-form-group
                      :label="$t('general.field_lang', { field: $t('labels.second_step_description'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                    >
                      <b-form-textarea
                        v-model='form.second_step_description[k]'
                        :placeholder="$t('general.field_lang', { field: $t('labels.second_step_description'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                      />
                      <small class='text-danger'>{{ errors[0] }}</small>
                    </b-form-group>
                  </ValidationProvider>
                </template>
              </LocaleTabs>
            </b-col>


<!--            <b-col-->
<!--              cols='12'-->
<!--            >-->
<!--              <LocaleTabs-->
<!--                :errors='formErrors'-->
<!--                name='third_step_title'-->

<!--              >-->
<!--                <template-->
<!--                  v-for='(i,k,idx) in languages'-->
<!--                  :slot='`lang${idx}`'-->
<!--                  slot-scope='slotScope'-->
<!--                >-->
<!--                  <ValidationProvider-->
<!--                    :key='idx'-->
<!--                    v-slot='{ errors }'-->
<!--                    :class="slotScope.selectedTab!==idx?'hidden':''"-->
<!--                    :vid='`second_step_title.${k}`'-->
<!--                    :name="$t('general.field_lang', { field: $t('labels.third_step_title'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"-->
<!--                    rules='required'-->
<!--                  >-->
<!--                    <b-form-group-->
<!--                      :label="$t('general.field_lang', { field: $t('labels.third_step_title'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"-->
<!--                    >-->
<!--                      <b-form-input-->
<!--                        v-model='form.third_step_title[k]'-->
<!--                        :placeholder="$t('general.field_lang', { field: $t('labels.third_step_title'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"-->
<!--                      />-->
<!--                      <small class='text-danger'>{{ errors[0] }}</small>-->
<!--                    </b-form-group>-->
<!--                  </ValidationProvider>-->
<!--                </template>-->
<!--              </LocaleTabs>-->
<!--            </b-col>-->

<!--            <b-col cols='12'>-->
<!--              <LocaleTabs-->
<!--                :errors='formErrors'-->
<!--                name='third_step_description'-->

<!--              >-->
<!--                <template-->
<!--                  v-for='(i,k,idx) in languages'-->
<!--                  :slot='`lang${idx}`'-->
<!--                  slot-scope='slotScope'-->
<!--                >-->
<!--                  <ValidationProvider-->
<!--                    :key='idx'-->
<!--                    v-slot='{ errors }'-->
<!--                    :class="slotScope.selectedTab!==idx?'hidden':''"-->
<!--                    :vid='`third_step_description.${k}`'-->
<!--                    :name="$t('general.field_lang', { field: $t('labels.third_step_description'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"-->
<!--                    rules='required'-->

<!--                  >-->
<!--                    <b-form-group-->
<!--                      :label="$t('general.field_lang', { field: $t('labels.third_step_description'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"-->
<!--                    >-->
<!--                      <b-form-textarea-->
<!--                        v-model='form.third_step_description[k]'-->
<!--                        :placeholder="$t('general.field_lang', { field: $t('labels.third_step_description'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"-->
<!--                      />-->
<!--                      <small class='text-danger'>{{ errors[0] }}</small>-->
<!--                    </b-form-group>-->
<!--                  </ValidationProvider>-->
<!--                </template>-->
<!--              </LocaleTabs>-->
<!--            </b-col>-->


            <b-col
              md='6'
              cols='12'
            >
              <validation-provider
                v-slot='{ errors }'
                :name="$t('labels.first_duration')"
                vid='first_duration'
                rules='required'

              >
                <b-form-group>
                  <template #label>
                    <span>{{ $t('labels.first_duration') }}</span>
                  </template>
                  <b-form-input
                    style='direction: ltr ;text-align: right'
                    v-model='form.first_duration'
                    autocomplete='off'
                    type='number'
                  />
                  <small class='text-danger'>{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              md='6'
              cols='12'
            >
              <validation-provider
                v-slot='{ errors }'
                :name="$t('labels.second_duration')"
                vid='second_duration'
                rules='required'

              >
                <b-form-group>
                  <template #label>
                    <span>{{ $t('labels.second_duration') }}</span>
                  </template>
                  <b-form-input
                    style='direction: ltr ;text-align: right'
                    v-model='form.second_duration'
                    autocomplete='off'
                    type='number'
                  />
                  <small class='text-danger'>{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              md='6'
              cols='12'
            >
              <validation-provider
                v-slot='{ errors }'
                :name="$t('labels.first_price')"
                vid='first_price'
              >
                <b-form-group>
                  <template #label>
                    <span>{{ $t('labels.first_price') }}</span>
                  </template>
                  <b-form-input
                    style='direction: ltr ;text-align: right'
                    v-model='form.first_price'
                    autocomplete='off'
                    type='number'
                  />
                  <small class='text-danger'>{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              md='6'
              cols='12'
            >
              <validation-provider
                v-slot='{ errors }'
                :name="$t('labels.second_price')"
                vid='second_price'
                rules='required'
              >
                <b-form-group>
                  <template #label>
                    <span>{{ $t('labels.second_price') }}</span>
                  </template>
                  <b-form-input
                    style='direction: ltr ;text-align: right'
                    v-model='form.second_price'
                    autocomplete='off'
                    type='number'
                  />
                  <small class='text-danger'>{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              md='6'
              cols='12'
            >
              <validation-provider
                v-slot='{ errors }'
                :name="$t('labels.third_duration')"
                vid='third_duration'
                rules='required'

              >
                <b-form-group>
                  <template #label>
                    <span>{{ $t('labels.third_duration') }}</span>
                  </template>
                  <b-form-input
                    style='direction: ltr ;text-align: right'
                    v-model='form.third_duration'
                    autocomplete='off'
                    type='number'
                  />
                  <small class='text-danger'>{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              md='6'
              cols='12'
            >
              <validation-provider
                v-slot='{ errors }'
                :name="$t('labels.third_price')"
                vid='third_price'
                rules='required'
              >
                <b-form-group>
                  <template #label>
                    <span>{{ $t('labels.third_price') }}</span>
                  </template>
                  <b-form-input
                    style='direction: ltr ;text-align: right'
                    v-model='form.third_price'
                    autocomplete='off'
                    type='number'
                  />
                  <small class='text-danger'>{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>


            <b-col cols='12' class='my-2'>
              <ValidationProvider
                v-slot='{ errors }'
                vid='enc_file'
                :name="$t('labels.enc_file')"
                rules='required'
              >
                <ImagePreview
                  v-model='form.enc_file'
                  :label="$t('labels.enc_file')"
                  :isImage='false'
                  accept-type='.pdf'
                />
                <small class='text-danger'>{{ errors[0] }}</small>
              </ValidationProvider>
              <div>
                <a target='_blank' :href='enc_file'>{{ $t('preview-file') }}</a>
              </div>
              <hr class='my-1'>
            </b-col>

            <b-col cols='12' class='my-2'>
              <ValidationProvider
                v-slot='{ errors }'
                vid='dec_file'
                :name="$t('labels.dec_file')"
              >
                <ImagePreview
                  v-model='form.dec_file'
                  :label="$t('labels.dec_file')"
                  :isImage='false'
                  accept-type='.pdf'
                />
                <small class='text-danger'>{{ errors[0] }}</small>
              </ValidationProvider>

              <div>
                <a target='_blank' :href='dec_file'>{{ $t('preview-file') }}</a>
              </div>

              <hr class='my-1'>
            </b-col>



            <!--            <b-col-->
            <!--              md='6'-->
            <!--              cols='12'-->
            <!--            >-->
            <!--              <validation-provider-->
            <!--                v-slot='{ errors }'-->
            <!--                :name="$t('sort')"-->
            <!--                vid='sort'-->
            <!--                :rules='{-->
            <!--                         numeric: true,-->
            <!--                       }'-->
            <!--              >-->
            <!--                <b-form-group>-->
            <!--                  <template #label>-->
            <!--                    <span>{{ $t('sort') }}</span>-->
            <!--                  </template>-->
            <!--                  <b-form-input-->
            <!--                    v-model='form.sort'-->
            <!--                    :placeholder="$t('sort')"-->
            <!--                    autocomplete='off'-->
            <!--                    type='number'-->
            <!--                  />-->
            <!--                  <small class='text-danger'>{{ errors[0] }}</small>-->
            <!--                </b-form-group>-->
            <!--              </validation-provider>-->
            <!--            </b-col>-->


                        <b-col cols='12'>
                          <ValidationProvider
                            v-slot='{ errors }'
                            vid='image'
                            :name="$t('labels.image')"
                            rules='required'
                          >
                            <ImagePreview
                              v-model='form.image'
                              :label="$t('labels.image')"
                              />
                            <small class='text-danger'>{{ errors[0] }}</small>
                          </ValidationProvider>
                          <hr class='my-1'>
                        </b-col>


            <!-- checkbox -->
            <!--            <b-col cols='12'>-->
            <!--              <b-card-text class='mb-0'>-->
            <!--                {{ $t('labels.status') }}-->
            <!--              </b-card-text>-->
            <!--              <b-form-checkbox-->
            <!--                v-model='form.status'-->
            <!--                checked='1'-->
            <!--                value='1'-->
            <!--                unchecked-value='0'-->
            <!--                name='check-button'-->
            <!--                switch-->
            <!--              />-->
            <!--            </b-col>-->

            <!-- submit and reset -->
            <b-col cols='12 text-right'>
              <LoadingButton />
              <b-button
                type='reset'
                variant='outline-secondary'
              >
                {{ $t('labels.reset') }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>
<script>
import formMixin from '@/mixins/formMixin'
import { websiteMainUrl } from '@/services/url.service'
import { BFormRadio } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import _ from 'lodash'

export default {
  components: { BFormRadio },
  mixins: [formMixin],
  data() {
    return {
      enc_file: null,
      dec_file: null,

      form: {
        sort: null,
        banner_title: {
          en: null,
          ar: null,
        },
        first_step_title: {
          en: null,
          ar: null,
        },
        second_step_title: {
          en: null,
          ar: null,
        },
        third_step_title: {
          en: null,
          ar: null,
        },
        first_step_description: {
          en: null,
          ar: null,
        },
        second_step_description: {
          en: null,
          ar: null,
        },
        third_step_description: {
          en: null,
          ar: null,
        },

        first_label_value: {
          en: null,
          ar: null,
        },

        second_label_value: {
          en: null,
          ar: null,
        },

        first_value: {
          en: null,
          ar: null,
        },
        second_value: {
          en: null,
          ar: null,
        },

        value: {
          first: null,
          second: null,
        },

        first_price: null,
        second_price: null,
        third_duration:null,
        third_price:null,

        first_duration: null,
        second_duration: null,

        image: null,
        enc_file: null,
        dec_file: null,


        expired_date: null,

        // status: 1,

      },

      isDataLoaded: false,
      isUploaderLoaded: false,


    }
  },


  computed: {
    toRedirectResource() {
      return 'opportunity-hub'
    },
  },
  created() {


    if (this.isEdit) {
      this.loadData()
    } else {
      this.isDataLoaded = true
    }
  },
  methods: {

    handleImageError(event, index = 0) {
      if (this.imagePreviews.length > 0) {
        event.target.src = this.imagePreviews[index]
      } else {
        event.target.src = require('@/assets/images/image.svg')
      }
    },


    loadData() {
      this.axios.get(`/${this.resource}/${this.resourceId}`)
        .then(res => {
          const { data } = res.data
          this.form = {
            first_step_title: data?.first_step_title['ar'] ?? false ? data.first_step_title : { en: null, ar: null },
            second_step_title: data?.second_step_title['ar'] ?? false ? data.second_step_title : { en: null, ar: null },
            third_step_title: data?.third_step_title['ar'] ?? false ? data.third_step_title : { en: null, ar: null },

            first_step_description: data?.first_step_description['ar'] ?? false ? data.first_step_description : {
              en: null,
              ar: null,
            },
            second_step_description: data?.second_step_description['ar'] ?? false ? data.second_step_description : {
              en: null,
              ar: null,
            },
            third_step_description: data?.third_step_description['ar'] ?? false ? data.third_step_description : {
              en: null,
              ar: null,
            },


            first_label_value: data?.first_label_value['ar'] ?? false ? data.first_label_value : { en: null, ar: null },
            second_label_value: data?.second_label_value['ar'] ?? false ? data.second_label_value : {
              en: null,
              ar: null,
            },

            banner_title: data?.banner_title['ar'] ?? false ? data.banner_title : { en: null, ar: null },


            first_price: data.first_price,
            second_price: data.second_price,
            third_price: data.third_price,

            first_duration: data.first_duration,
            second_duration: data.second_duration,
            third_duration: data.third_duration,

            first_value: data?.value['first']['ar'] ?? false ? data?.value['first'] : { en: null, ar: null },
            second_value: data?.value['second']['ar'] ?? false ? data?.value['second'] : { en: null, ar: null },

            value: data?.value['first'] ?? false ? { first: null, second: null } : { first: null, second: null },

            sort: data.sort,
            // status: data.status,
            image: data.image,
            enc_file: data.enc_file,
            dec_file: data.dec_file,

            expired_date: data.expired_date,

          }

          this.enc_file = data.enc_file
          this.dec_file = data.dec_file

          this.isDataLoaded = true
        })
    },

    loadFormData() {
      let $form = _.cloneDeep(this.form)

      $form.value['first'] = this.form.first_value
      $form.value['second'] = this.form.second_value

      $form = {
        ...$form,
      }

      return $form
    },
  },

}
</script>


