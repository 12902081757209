<template>
  <div>
    <b-card no-body>
      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              :label="$t('labels.name')"
              label-for="banner_title"
            >
              <b-form-input
                id="banner_title"
                :value="searchFields.banner_title"
                @input="inputChange($event, 'banner_title')"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <label>{{ $t('labels.status') }}</label>
            <v-select
              v-model="searchFields.status"
              :dir="$store.state.appConfig.layout.direction"
              :options="status_list"
              :reduce="item => item.value"
              label="label"
              class="w-100"
            >
              <template #no-options>{{ $t('No options') }}</template>
            </v-select>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <TableIndex
      :resource-name="resourceName"
      :singular-name="singularName"
      :filter-fields="searchFields"
      :permissionKey='"opportunity"'
      :can-change-status='false'
      :custom-change-status='selectedItemToChangeStatus'
      :have-dropdown-actions='true'
      :can-splice='false'
    >
      <template v-slot:dropdown_actions='{ item, generalActions }'>
        <b-dropdown-item
          v-if='(item.actions && item.actions.can_change_status)'
          @click='showModal(item,item.status)'
        >
          <feather-icon
            icon='FilterIcon'
            class='mr-50'
          />
          <span>{{ $t('status') }}</span>
        </b-dropdown-item>

      </template>
    </TableIndex>

    <b-modal
      id='modal-status'
      ref='modal-status'
      cancel-variant='outline-secondary'
      :ok-title="$t('yes')"
      :cancel-title="$t('no')"
      centered
      :title="$t('change_status')"
      hide-footer
      :cancel-disabled='false'
    >
      <validation-observer
        ref='form'
        v-slot='{invalid}'
      >
        <b-form
          novalidate
          @submit.prevent='changeStatus'
        >
          <validation-provider
            v-slot='{ errors }'
            vid='status'
            :name="$t('status')"
            rules='required'
          >
            <b-form-group
              :label="$t('status')"
            >
              <v-select
                v-model='form.status'
                :dir='$store.state.appConfig.layout.direction'
                label='label'
                :options='status_list'
                :reduce='item => item.value'
                :placeholder="$t('select')"
              >
                <template #no-options>{{ $t('No options') }}</template>
              </v-select>
              <!--              <small class='text-danger'>{{ errors[0] }}</small>-->
            </b-form-group>
          </validation-provider>
          <div class='d-flex'>
            <LoadingButton :loader='loader' />
            <b-button
              variant='outline-secondary'
              @click='hideModal'
            >
              {{ $t('close') }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>

  </div>
</template>

<script>
import listMixin from '@/mixins/listMixin'

export default {
  mixins: [listMixin],
  data() {
    return {
      resourceName: 'opportunity-hub',
      singularName: 'opportunity-hub',
      status_list:[],
      selectedItemToChangeStatus: null,
      item_id: null,
      loader: false,

      form: {
        status: null,
      },

      searchFields: {
        banner_title: null,
        status: null,
      },
    }
  },

  created() {
    this.axios.get(`/model-list/opportunity-hubs-status-list`)
      .then(res => {
        this.status_list = res.data.data
      })
  },
  methods: {
    // eslint-disable-next-line func-names
    inputChange: _.debounce(function (e, field) {
      this.searchFields[field] = e
    }, 500),
    showModal(item, status) {
      status === 'approved' ? this.form.status = null : this.form.status = status

      this.$refs['modal-status'].show()

      this.item_id = item.id

     },

    hideModal() {
      this.$refs['modal-status'].hide()
      setTimeout(() => {
        this.item_id = null
        this.form.status = null
      }, 500)
    },
    changeStatus() {
      this.$refs.form.validate()
        .then(result => {
          if (result) {
            this.loader = true;
            this.axios.patch(`/opportunity-hub/${this.item_id}/change-status`, this.form)
              .then(response => {
                this.selectedItemToChangeStatus = response.data.data
                this.hideModal()
                this.loader = false;

                setTimeout(() => {
                  this.$bvToast.toast(response.data.message, {
                    title: this.$t('Success'),
                    variant: 'success',
                    solid: true,
                    autoHideDelay: 5000,
                    appendToast: true,
                  }, 500)
                  this.form.status = null
                })
              }).catch(error => {

              this.$bvToast.toast(error.response.data.message, {
                title: this.$t('Error'),
                variant: 'danger',
                solid: true,
                autoHideDelay: 5000,
                appendToast: true,
              })
               this.loader = false;
            })
          }
        })
    },
  },
  // eslint-disable-next-line vue/order-in-components

}
</script>
