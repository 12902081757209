<template>
  <div>
    <validation-observer
      ref='form'
      v-slot='{invalid}'
    >

      <Spinner :is-data-loaded='isDataLoaded' />


      <b-form
        v-if='isDataLoaded'
        novalidate
        @submit.prevent='onSubmit'
      >
        <b-card>

          <!--Tabs information-->
          <ul class='custom-tabs-form'>

            <li
              :class="{'active' : selectedTab === 'advertisement_details','danger': advertisement_details_errors_length > 0}"
              @click="selectTab('advertisement_details')"
            >
              <span>{{ $t('advertisement_details') }}</span> <span
              v-if='advertisement_details_errors_length > 0'
              class='num-error-style'
            >{{ advertisement_details_errors_length }}</span>
            </li>
            <li
              :class="{'active' : selectedTab === 'property_information','danger': property_information_errors_length > 0}"
              @click="selectTab('property_information')"
              v-if='columns_real_estate_values.length !== 0'

            >
              <span>{{ $t('property_information') }}</span> <span
              v-if='property_information_errors_length > 0'
              class='num-error-style'
            >{{ property_information_errors_length }}</span>
            </li>

            <!--            <li-->
            <!--              :class="{'active' : selectedTab === 'geographical_location','danger': geographical_location_errors_length > 0}"-->
            <!--              @click="selectTab('geographical_location')"-->
            <!--            >-->
            <!--              <span>{{ $t('geographical_location') }}</span> <span-->
            <!--              v-if='geographical_location_errors_length > 0'-->
            <!--              class='num-error-style'-->
            <!--            >{{ geographical_location_errors_length }}</span>-->
            <!--            </li>-->

          </ul>

          <!--Map-->
          <!--          <div class='position-relative'>-->
          <!--            <div v-if='selectedTab !== "geographical_location"' class='hide-map-layer'></div>-->

          <!--            <div :class="{'hide-map': selectedTab !== 'geographical_location' }">-->
          <!--              <label-->
          <!--                  style='font-weight: 500;font-size: 16px'-->
          <!--                  class='mb-1'-->
          <!--              >-->
          <!--                {{ $t('Choose the address from the map or fill in the fields below') }}-->
          <!--              </label>-->

          <!--              <div class='map-app' :class="{'hide-map': selectedTab !== 'geographical_location' }">-->
          <!--                <div id='map-layout'>-->
          <!--                  <Map @childDataUpdated='handleMapData' :modelValue='map_coordinate' />-->
          <!--                </div>-->
          <!--              </div>-->
          <!--            </div>-->

          <!--          </div>-->

          <!--General information Tab-->
          <div class='tab-layer' v-show="selectedTab === 'advertisement_details'">
            <b-row>

              <b-col cols='12' md='6'>
                <NewCustomerSelector :isEdit='isEdit' @update:customer_id='handleCustomerIdsUpdate' />
              </b-col>

              <b-col
                cols='12'
                md='6'
                class='mb-md-0 mb-2'
                v-if='isEdit && form.customer_name'
              >
                <b-form-group
                  :label="$t('creator_name')"
                  label-for='creator_name'
                  v-if='isEdit && form.customer_name'
                >
                  <b-form-input
                    style='direction: ltr; text-align: right;display: flex;justify-content: end'
                    id='creator_name'
                    type='text'
                    :value='form.customer_name'
                    disabled
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols='12'
                md='6'
                class='mb-md-0 mb-2'
                v-if='isEdit && form.advertiser_phone'
              >
                <b-form-group
                  :label="$t('creator_mobile')"
                  label-for='creator_mobile'
                  v-if='isEdit'
                >
                  <b-form-input
                    style='direction: ltr; text-align: right;display: flex;justify-content: end'
                    id='creator_mobile'
                    type='text'
                    :value='form.advertiser_phone'
                    disabled
                  />
                </b-form-group>
              </b-col>


              <b-col
                cols='12'
                md='6'
              >
                <validation-provider
                  v-slot='{ errors }'
                  vid='sub_type_id'
                  :name="$t('the_sub_type_of_ad')"
                  rules='required'
                >
                  <b-form-group
                    :label="$t('the_sub_type_of_ad')"
                  >

                    <v-select
                      v-model='form.the_sub_type_of_ad'
                      :dir='$store.state.appConfig.layout.direction'
                      label='name'
                      :options='the_sub_type_of_ads'
                      :reduce='item => item.id'
                      :placeholder="$t('select')"
                    >
                      <template #no-options>{{ $t('No options') }}</template>
                    </v-select>
                    <small class='text-danger'>{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col
                cols='12'
                md='6'
              >
                <validation-provider
                  v-slot='{ errors }'
                  vid='privacy_type'
                  :name="$t('privacy_type')"
                  rules='required'
                >
                  <b-form-group
                    :label="$t('privacy_type')"
                  >
                    <v-select
                      v-model='form.privacy_type'
                      :dir='$store.state.appConfig.layout.direction'
                      label='label'
                      :options='privacy_list'
                      :reduce='item => item.value'
                      :placeholder="$t('select')"
                      disabled
                    >
                      <template #no-options>
                        {{ $t('No options') }}
                      </template>
                    </v-select>
                    <small class='text-danger'>{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>


              <b-col
                cols='12'
                md='6'
                v-if='usages.length'
              >
                <validation-provider
                  v-slot='{ errors }'
                  vid='usage_id'
                  :name="$t('usage_id')"
                  rules='required'
                >
                  <b-form-group
                    :label="$t('usage_id')"
                  >
                    <v-select
                      v-model='form.usage_id'
                      :dir='$store.state.appConfig.layout.direction'
                      label='name'
                      :options='usages'
                      :reduce='item => item.id'
                      :placeholder="$t('select')"
                      @input="inputChange('usage_id')"
                    >
                      <template #no-options>{{ $t('No options') }}</template>
                    </v-select>
                    <small class='text-danger'>{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols='12'
                md='6'
                v-if='types.length'
              >
                <validation-provider
                  v-slot='{ errors }'
                  vid='type_id'
                  :name="$t('type_id')"
                  rules='required'
                >
                  <b-form-group
                    :label="$t('type_id')"
                  >
                    <v-select
                      v-model='form.type_id'
                      :dir='$store.state.appConfig.layout.direction'
                      label='name'
                      :options='types'
                      :reduce='item => item.id'
                      :placeholder="$t('select')"
                      @input="inputChange('type_id')"

                    >
                      <template #no-options>{{ $t('No options') }}</template>
                    </v-select>
                    <small class='text-danger'>{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col
                cols='12'
                md='6'
                v-if='sub_types.length'
              >
                <validation-provider
                  v-slot='{ errors }'
                  vid='sub_type_id'
                  :name="$t('sub_type_id')"
                  rules='required'
                >
                  <b-form-group
                    :label="$t('sub_type_id')"
                  >
                    <v-select
                      v-model='form.sub_type_id'
                      :dir='$store.state.appConfig.layout.direction'
                      label='name'
                      :options='sub_types'
                      :reduce='item => item.id'
                      :placeholder="$t('select')"
                      @input="inputChange('sub_type_id')"

                    >
                      <template #no-options>{{ $t('No options') }}</template>
                    </v-select>
                    <small class='text-danger'>{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col
                cols='12'
                md='6'
              >
                <validation-provider
                  v-slot='{ errors }'
                  vid='region_id'
                  :name="$t('region')"
                  rules='required'
                >
                  <b-form-group
                    :label="$t('region')"
                  >
                    <v-select
                      v-model='form.region_id'
                      :dir='$store.state.appConfig.layout.direction'
                      label='name'
                      :options='regions'
                      :reduce='item => item.id'
                      :placeholder="$t('select')"

                      @input="inputChange('region_id')"
                    >
                      <template #no-options>
                        {{ $t('No options') }}
                      </template>
                    </v-select>
                    <small class='text-danger'>{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col
                cols='12'
                md='6'
                v-if='cities.length'
              >
                <validation-provider
                  v-slot='{ errors }'
                  vid='city_id'
                  :name="$t('city')"
                  rules='required'
                >
                  <b-form-group
                    :label="$t('city')"
                  >
                    <v-select
                      v-model='form.city_id'
                      :dir='$store.state.appConfig.layout.direction'
                      label='name'
                      :options='cities'
                      :reduce='item => item.id'
                      :placeholder="$t('select')"

                      @input="inputChange('city_id')"
                    >
                      <template #no-options>{{ $t('No options') }}</template>
                    </v-select>
                    <small class='text-danger'>{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols='12'
                md='6'
                v-if='districts.length'
              >
                <validation-provider
                  v-slot='{ errors }'
                  vid='district_id'
                  :name="$t('district')"
                >

                  <b-form-group
                    :label="$t('district')"
                  >
                    <v-select
                      v-model='form.district_id'
                      :dir='$store.state.appConfig.layout.direction'
                      label='name'
                      :options='districts'
                      :reduce='item => item.id'
                      :placeholder="$t('select')"
                      multiple
                    >
                      <template #no-options>{{ $t('No options') }}</template>
                    </v-select>
                    <small class='text-danger'>{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>


              <b-col
                cols='12'
                md='6'
                class='mb-md-0 mb-2'
                v-if='form.privacy_type == 1'
              >
                <b-form-group
                  :label="$t('real_estate')"
                  label-for='real_estate'
                >
                  <div class='d-flex justify-content-between w-100'>
                    <b-form-input
                      style='direction: ltr; text-align: right;display: flex;justify-content: end'
                      id='real_estate'
                      type='text'
                      v-model='real_estate'
                    />
                    <button id='addRealEstate'
                            type='button'
                            class='btn bg-success ml-1' @click.prevent='addRealEstate'>+
                    </button>
                  </div>
                </b-form-group>

                <div class='d-flex flex-wrap align-items-center'>
                  <div class='mb-1 bg-primary rounded mr-1 d-flex justify-content-between align-items-center'
                       style='padding: 5px 20px' v-for='(real_estate, index) in form.real_estates'
                       :key='index'

                  >
                     <span
                       class='cursor-pointer text-danger bg-white rounded-circle d-flex justify-content-center align-items-center mr-1'
                       style='width: 15px;height: 15px;font-size: 10px'
                       @click='deleteRealEstate(index)'>X</span>

                    <span>{{ real_estate }}</span>
                  </div>
                </div>
              </b-col>

              <b-col
                cols='12'
                class='d-flex justify-content-center mt-2 gap-20'
              >

                <b-button
                  variant='outline-secondary'
                  @click="selectTab('property_information')"
                  v-if='columns_real_estate_values.length !== 0'
                >
                  {{ $t('next') }}
                </b-button>
              </b-col>
            </b-row>
          </div>

          <!--Property information Tab-->
          <div class='tab-layer' v-show="selectedTab === 'property_information'">
            <b-row>

              <b-col
                cols='12'
                md='6'
                v-for='(column, index) in columns_real_estate_values'
                :key='index'
              >

                <validation-provider
                  v-slot='{ errors }'
                  :name='column.label'
                  :vid='column.key'
                  :rules="(column.is_required ? 'required' : '') + (column.type == 'number' ? (column.is_required ? '|' : '') + 'numeric' : '')"
                  v-if='column.type =="number" || column.type =="text"'
                >
                  <b-form-group
                    :label='column.label'
                  >
                    <b-form-input
                      v-model='form.info_fields[column.key]'
                      autocomplete='off'
                      :type='column.type === "text" ? "text" :"number"'
                      :placeholder='column.placeholder'
                      style='height: 50px'
                    />
                    <small class='text-danger'>{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>

                <validation-provider
                  v-slot='{ errors }'
                  :name='column.label'
                  :vid='column.key'
                  :rules="column.is_required ? 'required' : ''"
                  v-if='column.type =="between"'
                >
                  <b-form-group
                    :label='column.label'
                  >
                    <div class='d-flex'>
                      <b-form-input
                        v-model='form.info_fields["from_"+column.key]'
                        autocomplete='off'
                        type='number'
                        :placeholder='column.placeholder_to'
                        style='height: 50px'
                      />
                      <b-form-input
                        v-model='form.info_fields["to_"+column.key]'
                        autocomplete='off'
                        type='number'
                        :placeholder='column.placeholder_from'
                        style='height: 50px;margin-right: 8px'
                      />
                    </div>
                    <small class='text-danger'>{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>


                <validation-provider
                  v-slot='{ errors }'
                  :name='column.label'
                  :vid='column.key'
                  :rules="column.is_required ? 'required' : ''"
                  v-if='column.type =="text_area"'
                >
                  <b-form-group
                    :label='column.label'
                  >
                    <b-form-textarea
                      v-model='form.info_fields[column.key]'
                      :placeholder='column.placeholder'
                      autocomplete='off'
                      rows='5'
                    />
                    <small class='text-danger'>{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>

                <validation-provider
                  v-slot='{ errors }'
                  :vid='column.key'
                  :name='column.label'
                  :rules='column.is_required ?"required":""'
                  v-else-if='column.type =="list"'
                >
                  <b-form-group
                    :label='column.label'
                  >
                    <v-select
                      v-model='form.info_fields[column.key]'
                      :dir='$store.state.appConfig.layout.direction'
                      label='label'
                      :options='column.options'
                      :reduce='item => item.value'
                      :placeholder="$t('select')"
                    >
                      <template #no-options>{{ $t('No options') }}</template>
                    </v-select>
                    <small class='text-danger'>{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>

                <validation-provider
                  v-slot='{ errors }'
                  :vid='column.key'
                  :name='column.label'
                  :rules='column.is_required ?"required":""'
                  v-else-if='column.type =="checkbox"'
                >
                  <b-form-group
                    :label='column.label'
                  >

                    <v-select
                      v-model='form.info_fields[column.key]'
                      :dir='$store.state.appConfig.layout.direction'
                      label='label'
                      :options='column.options'
                      :reduce='item => item.value.toString()'
                      :placeholder="$t('select')"
                      multiple
                    >
                      <template #no-options>{{ $t('No options') }}</template>
                    </v-select>
                    <small class='text-danger'>{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>

                <!--                :rules="column.is_required ? 'required':''"-->

                <validation-provider
                  v-slot='{ errors }'
                  :name='column.label'
                  :vid='column.key'
                  :rules="'required'"
                  v-else-if='column.type =="radio"'
                >
                  <b-form-group
                    :label='column.label'
                  >
                    <div
                      class='border rounded-sm p-1 d-flex custom-gap-40'
                    >

                      <b-form-radio
                        v-for='(option, key) in column.options'
                        :key='key'
                        v-model='form.info_fields[column.key]'
                        :name='column.key'
                        :value='option.value'
                      >
                        {{ option.label }}
                      </b-form-radio>

                    </div>
                    <small class='text-danger'>{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>

                <validation-provider
                  v-slot='{ errors }'
                  :name='column.label'
                  :vid='column.key'
                  :rules="column.is_required ? 'required' : ''"
                  v-if='column.type =="range"'
                >
                  <b-form-group
                    :label='column.label'
                  >
                    <vue-slider
                      :process-style='{background:"#b27354"}'
                      :tooltip-formatter='formatter2'
                      v-model='form.info_fields[column.key]' :min='column.min' :max='column.max' />


                    <small class='text-danger'>{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>

              </b-col>


              <b-col
                cols='12'
                class='d-flex justify-content-center mt-2 gap-20'
              >
                <b-button
                  variant='outline-secondary'
                  @click="selectTab('advertisement_details')"
                >
                  {{ $t('previous') }}
                </b-button>
                <LoadingButton />
              </b-col>
            </b-row>
          </div>


          <!--Geographical location Tab-->
          <!--          <div class='tab-layer' v-show="selectedTab === 'geographical_location'">-->
          <!--            <b-row class='mt-2'>-->

          <!--              <b-col-->
          <!--                cols='12'-->
          <!--                class='d-flex justify-content-center mt-2 gap-20'-->
          <!--              >-->
          <!--                <b-button-->
          <!--                  variant='outline-secondary'-->
          <!--                  @click="selectTab('attachments')"-->
          <!--                >-->
          <!--                  {{ $t('previous') }}-->
          <!--                </b-button>-->
          <!--                <LoadingButton />-->
          <!--              </b-col>-->
          <!--            </b-row>-->
          <!--          </div>-->


        </b-card>
      </b-form>
    </validation-observer>
  </div>
</template>
<script>
import _ from 'lodash'
import { BFormRadio } from 'bootstrap-vue'
import { websiteMainUrl } from '@/services/url.service'

import formMixin from '@/mixins/formMixin'
import 'leaflet/dist/leaflet.css'

import Cleave from 'vue-cleave-component'

import Map from '@/components/Map.vue'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Spinner from '@/components/Spinner.vue'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'
import NewCustomerSelector from '@/components/NewCustomerSelector.vue'

export default {
  components: {
    NewCustomerSelector,
    VueSlider,
    Spinner,
    BFormRadio,
    Cleave,
    Map,
  },
  mixins: [formMixin],

  computed: {
    toRedirectResource() {
      if (this.$route.name === 'edit-real-estate-requests-private' || this.$route.name === 'add-real-estate-requests-private') {
        return 'real-estate-requests-private'
      }

      return 'real-estate-requests'

    },
  },

  data() {
    return {
      cleaveOptions: {
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
      },
      selectedTab: 'advertisement_details',
      websiteUrl: websiteMainUrl(),
      the_sub_type_of_ads: [],
      usages: [],
      types: [],
      sub_types: [],
      regions: [],
      cities: [],
      districts: [],
      privacy_list: [
        {
          label: this.$t('public'),
          value: 0,
        },
        {
          label: this.$t('private'),
          value: 1,
        },
      ],
      real_estate: null,


      form: {

        //tab 1
        new_creator_id: null,
        privacy_type: 0,


        // Tab 2
        usage_id: null,
        type_id: null,
        sub_type_id: null,
        info_fields: {},


        // Tab 4
        city_id: null,
        district_id: [],

        region_id: null,
        real_estates: [],

        // Tab 4


      },

      //errors tab 1

      advertisement_details_errors_length: 0,
      advertisement_details_errors: [
        'type_id',
        'usage_id',
        'sub_type_id',
        'city_id',
        'district_id',
      ],

      //errors tab 2
      property_information_errors_length: 0,
      property_information_errors: [],

      original_property_information_errors: [],

      //errors tab 4
      geographical_location_errors_length: 0,
      geographical_location_errors: [],


      columns_real_estate_values: [],

      isDataLoaded: false,
      isUploaderLoaded: false,
    }
  },

  watch: {


    formErrors: {
      deep: true,
      handler(val = null) {
        if (val) {
          this.getErrorTabLength(val, this.advertisement_details_errors, 'advertisement_details_errors_length')
          // this.getErrorTabLength(val, this.geographical_location_errors, 'geographical_location_errors_length')

          this.getErrorTabLength(val, this.property_information_errors, 'property_information_errors_length')

        }
      },
    },
    form: {
      deep: true,
      handler() {
        setTimeout(() => {
          const formErrors = this.$refs.form.errors
          this.formErrors = formErrors
          this.getErrorTabLength(formErrors, this.advertisement_details_errors, 'advertisement_details_errors_length')

          // this.getErrorTabLength(formErrors, this.geographical_location_errors, 'geographical_location_errors_length')
          this.getErrorTabLength(formErrors, this.property_information_errors, 'property_information_errors_length')


        }, 500)
      },
    },
  },
  created() {

    if (this.$route.name === 'edit-real-estate-requests-private' || this.$route.name === 'add-real-estate-requests-private') {
      this.form.privacy_type = 1;
    }

    (async () => {
      await this.getRegion()
    })();

    (async () => {
      await this.getLists()
    })();

    (async () => {
      await this.getUsages()
    })()

    if (this.isEdit) {
      this.loadData()
    } else {
      setTimeout(() => {
        this.isDataLoaded = true
      }, 2000)
    }

  },


  methods: {
    addRealEstate() {
      if (this.real_estate) {
        this.form.real_estates.push(this.real_estate)
        this.real_estate = null
      }
    },

    deleteRealEstate(index) {
      this.form.real_estates.splice(index, 1)
    },

    handleCustomerIdsUpdate(customerId) {
      if (customerId) {
        this.form.new_creator_id = customerId
      } else {
        this.form.new_creator_id = null
      }
    },

    inputChange(type) {

      if (type == 'region_id') {
        this.cities = []
        this.form.city_id = null
        this.districts = []
        this.form.district_id = [];

        (async () => {
          await this.getCities()
        })()
      }

      if (type == 'city_id') {
        this.districts = []
        this.form.district_id = [];

        (async () => {
          await this.getDistrict()
        })()
      }

      if (type == 'usage_id') {
        this.types = []

        this.form.type_id = null
        this.form.sub_type_id = null
        this.columns_real_estate_values = []

        this.sub_types = []


        this.getTypesUsage()
      }
      if (type == 'type_id') {
        this.form.sub_type_id = null
        this.sub_types = []

        this.columns_real_estate_values = []


        this.form.info_fields = {}

        this.getSubTypes()
      }

      if (type == 'sub_type_id') {

        this.columns_real_estate_values = []

        this.form.info_fields = {}
        this.getSubTypesDynamic()
      }

    },

    async handleMapData(data) {
      try {
        if (data) {


        } else {
          console.error(`Geocoding failed`)
        }
      } catch (error) {
        console.error(error)
      }
    },


    getErrorTabLength(errors, array, v) {
      let errorLength = 0
      for (const el in errors) {
        if (array.includes(el) && this.formErrors[el].length > 0) {
          errorLength++
        }
      }
      this[v] = errorLength
    },
    loadData() {
      const params = {
        is_edit: true,
      }

      this.axios.get(`/${this.resource}/${this.resourceId}`, { params })
        .then(res => {

          const { data } = res.data
          this.form = {
            ...this.form,
            ...data,
          }


          if (this.form.city_id) {
            (async () => {
              await this.getCities()
            })();
            (async () => {
              await this.getDistrict(this.form.city_id)
            })()
          }

          (async () => {
            await this.getTypesUsageForEdit()
          })()


          setTimeout(() => {
            this.isDataLoaded = true
          }, 2000)

        })
    },
    async getLists() {
      await this.axios.get(`/model-options/type-category?model=9&is_request=true`)
        .then(res => {
          this.the_sub_type_of_ads = res.data.data
        })
    },


    async getUsages() {
      await this.axios.get(`/model-options/usage`)
        .then(res => {
          this.usages = res.data.data
        })
    },

    selectTab(selected) {

      this.selectedTab = selected
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
      }, 100)
    },

    async getRegion() {
      await this.axios.get(`/model-options/region`)
        .then(res => {
          this.regions = res.data.data
        })
    },

    getCities() {
      if (this.form.city_id === null) {
        this.cities = []
      }

      this.axios.get(`/model-options/city?region_id=${this.form.region_id}`)
        .then(res => {
          this.cities = res.data.data
          return 1
        })
    },
    getDistrict() {

      if (this.form.district_id === []) {
        this.districts = []
      }

      this.axios.get(`/districts/list?city_id=${this.form.city_id}`)
        .then(res => {
          this.districts = res.data.data
        })
    },

    async getTypesUsage() {
      await this.axios.get(`/model-options/types?has_request_fields=true&usage_id=${this.form.usage_id}`)
        .then(res => {
          this.types = res.data.data
        })
    },

    async getTypesUsageForEdit() {
      await this.axios.get(`/model-options/types?has_request_fields=true&usage_id=${this.form.usage_id}`)
        .then(res => {
          this.types = res.data.data

          this.getSubTypes(true)
        })
    },

    async getSubTypes(is_edit = false) {

      const item = this.types?.filter(el => el.id === this.form.type_id)

      if (item[0]?.id) {

        if (!item[0].has_children) {
          this.getDynamicFields(item, is_edit)
        }
        //
        if (item[0].parent == null && item[0].has_children && !is_edit) {
          await this.axios.get(`/model-options/types?has_request_fields=true&usage_id=${this.form.usage_id}&parent_id=${item[0].id}`)
            .then(res => {
              this.form.sub_type_id = null
              this.sub_types = res.data.data
            })
        }

        if (item[0].parent == null && item[0].has_children && is_edit) {
          await this.axios.get(`/model-options/types?has_request_fields=true&usage_id=${this.form.usage_id}&parent_id=${item[0].id}`)
            .then(res => {
              this.sub_types = res.data.data
              this.getSubTypesDynamic(is_edit)
            })
        }
      }
    },

    getDynamicFields(item, is_edit) {
      this.columns_real_estate_values = item[0]?.required_request_fields


      //
      this.columns_real_estate_values = this.columns_real_estate_values.concat(item[0]?.not_required_request_fields)

      this.property_information_errors = this.original_property_information_errors


      this.columns_real_estate_values?.map((el) => {


        this.property_information_errors.push(el.key)

        if (!is_edit) {
          if (el.type === 'range') {
            let val = this.form.info_fields[el.key]
            this.form.info_fields[el.key] = val ? val : 1
          }

          if (el.type === 'radio') {
            let val = this.form.info_fields[el.key]
            this.form.info_fields[el.key] = val ? val : el.options[0]?.value
          }
        }

      })

    },

    getSubTypesDynamic(is_edit = false) {
      const sub_item = this.sub_types?.filter(el => el.id === this.form.sub_type_id)

      if (sub_item[0]?.id) {

        this.getDynamicFields(sub_item, is_edit)
        //

      }
    },

    formatter2(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },

    loadFormData() {
      let $form = _.cloneDeep(this.form)

      if (this.form.privacy_type == 0) {
        this.form.real_estates = []
      }

      $form = {
        ...$form,
      }
      return $form
    },
  },
}
</script>
<style lang='scss'>
.map-app {
  display: flex;
  height: 64vh;
  padding: 0;
  flex: 1;
}

#map-layout {
  flex: 1;
  display: flex;
  position: relative;
}

.hide-map {
  opacity: 0 !important;
  height: 0vh !important;
}

.mapboxgl-ctrl-logo {
  display: none !important;
}

.hide-map-layer {
  position: absolute;
  background: transparent;
  width: 100%;
  height: 70vh;

  display: flex;
  z-index: 3;
}

.tab-layer {
  position: relative;
  z-index: 4;
}

.uploader-btn {
  width: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  height: 40px;
}

</style>
