<template>
  <div>
    <b-card no-body>
      <b-card-body>
        <b-row>

          <b-col
            cols='12'
            md='4'
            class='mb-md-0 mb-2'
          >
            <label>{{ $t('type') }}</label>
            <v-select
              v-model='searchFields.type'
              :dir='$store.state.appConfig.layout.direction'
              :options='types'
              :reduce='item => item.id'
              label='name'
              class='w-100'
            >
              <template #no-options>{{ $t('No options') }}</template>
            </v-select>
          </b-col>
          <b-col
            cols='12'
            md='4'
            class='mb-md-0 mb-2'
          >
            <label>{{ $t('labels.status') }}</label>
            <v-select
              v-model='searchFields.status'
              :dir='$store.state.appConfig.layout.direction'
              :options='status_list'
              :reduce='item => item.value'
              label='label'
              class='w-100'
            >
              <template #no-options>{{ $t('No options') }}</template>
            </v-select>
          </b-col>


          <b-col
            cols='12'
            md='4'
          >
            <DatePicker
              :label="$t('labels.created_on_adv')"
              :reset='true'
              v-model='created_at'
            />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <TableIndex
      :resource-name='resourceName'
      :singular-name='singularName'
      :can-change-status='false'
      :have-dropdown-actions='true'
      :can-splice='false'
      :filter-fields='searchFields'
      :RealEstateAdd='true'
      :permissionKey='"payment"'

    >
      <template v-slot:dropdown_actions='{ item, generalActions }'>

        <b-dropdown-item v-if='item.status == 1 && item.amount != 0'  @click="downloadInvoice(item.id)">
          <feather-icon icon='EyeIcon' class='mr-50' />
          <span>{{ $t('download invoice') }}</span>
        </b-dropdown-item>

      </template>
    </TableIndex>

  </div>
</template>

<script>
import listMixin from '@/mixins/listMixin'
import { websiteMainUrl } from '@/services/url.service'
import NewCustomerSelector from '@/components/NewCustomerSelector.vue'


export default {
  components: { NewCustomerSelector },
  mixins: [listMixin],
  data() {
    return {
      resourceName: 'payment-customer',
      singularName: 'payment-customer',
      types: [
        {
          id: 1,
          name: this.$t('package')
        },
        {
          id: 2,
          name: this.$t('opportunity'),
        },
        {
          id: 3,
          name: this.$t('real_estate_private'),
        },
        {
          id: 4,
          name: this.$t('request_estate_private'),
        },
        {
          id: 5,
          name: this.$t('down_payment'),
        },

      ],

      loader: false,

      form: {
        status: null,
      },
      status_list: [],

      websiteUrl: websiteMainUrl(),
      created_at: null,


      searchFields: {
        status: null,
        created_at: null,
        type: null,
        customer_id: null,
      },
    }
  },
  watch: {

    'created_at': {
      handler(val) {
        if (this.searchFields['created_at'] == null && (val == '' || val == null)) {
          return true
        }

        this.searchFields['created_at'] = val
      },
    },

  },


  created() {
    this.searchFields.customer_id = this.$route.params.resourceId

    this.axios.get(`/model-list/payment-status-list`)
      .then(res => {
        this.status_list = res.data.data
      })

  },
  methods: {

    downloadInvoice(fileId) {
      this.axios({
        url: `${this.websiteUrl}/admin/payment-download-file/${fileId}`,
        method: 'GET',
        responseType: 'blob', // Important for file download
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `invoice-${fileId}.pdf`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((error) => {
          console.error('Error downloading invoice:', error);
          this.$bvToast.toast(this.$t('Failed to download invoice'), {
            title: 'Error',
            variant: 'danger',
            solid: true,
          });
        });
    },


    // eslint-disable-next-line func-names
    inputChange: _.debounce(function(e, field) {
      this.searchFields[field] = e
    }, 500),


  },
}
</script>
