var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{attrs:{"no-body":""}},[_c('b-card-body',[_c('b-row',[_c('b-col',{staticClass:"mb-md-0 mb-2",attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('labels.customer_name'),"label-for":"customer_name"}},[_c('b-form-input',{attrs:{"id":"customer_name","value":_vm.searchFields.customer_name},on:{"input":function($event){return _vm.inputChange($event, 'customer_name')}}})],1)],1),_c('b-col',{staticClass:"mb-md-0 mb-2",attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('labels.opportunity_description'),"label-for":"opportunity_description"}},[_c('b-form-input',{attrs:{"id":"opportunity_description","value":_vm.searchFields.opportunity_description},on:{"input":function($event){return _vm.inputChange($event, 'opportunity_description')}}})],1)],1),_c('b-col',{staticClass:"mb-md-0 mb-2",attrs:{"cols":"12","md":"4"}},[_c('label',[_vm._v(_vm._s(_vm.$t('labels.status')))]),_c('v-select',{staticClass:"w-100",attrs:{"dir":_vm.$store.state.appConfig.layout.direction,"options":_vm.status_list,"reduce":function (item) { return item.value; },"label":"label"},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(_vm._s(_vm.$t('No options')))]},proxy:true}]),model:{value:(_vm.searchFields.status),callback:function ($$v) {_vm.$set(_vm.searchFields, "status", $$v)},expression:"searchFields.status"}})],1)],1)],1)],1),_c('TableIndex',{attrs:{"resource-name":_vm.resourceName,"singular-name":_vm.singularName,"filter-fields":_vm.searchFields,"permissionKey":"customer-opportunity-hub","can-change-status":false,"custom-change-status":_vm.selectedItemToChangeStatus,"have-dropdown-actions":true,"can-splice":false},scopedSlots:_vm._u([{key:"dropdown_actions",fn:function(ref){
var item = ref.item;
var generalActions = ref.generalActions;
return [((item.actions && item.actions.can_change_status))?_c('b-dropdown-item',{on:{"click":function($event){return _vm.showModal(item,item.status)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"FilterIcon"}}),_c('span',[_vm._v(_vm._s(_vm.$t('status')))])],1):_vm._e()]}}])}),_c('b-modal',{ref:"modal-status",attrs:{"id":"modal-status","cancel-variant":"outline-secondary","ok-title":_vm.$t('yes'),"cancel-title":_vm.$t('no'),"centered":"","title":_vm.$t('change_status'),"hide-footer":"","cancel-disabled":false}},[_c('validation-observer',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.changeStatus($event)}}},[_c('validation-provider',{attrs:{"vid":"status","name":_vm.$t('status'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('status')}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.layout.direction,"label":"label","options":_vm.status_list,"reduce":function (item) { return item.value; },"placeholder":_vm.$t('select')},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(_vm._s(_vm.$t('No options')))]},proxy:true}],null,true),model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}})],1)]}}],null,true)}),_c('div',{staticClass:"d-flex"},[_c('LoadingButton',{attrs:{"loader":_vm.loader}}),_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":_vm.hideModal}},[_vm._v(" "+_vm._s(_vm.$t('close'))+" ")])],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }