var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('validation-observer',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [(!_vm.isDataLoaded)?_c('b-card',[_c('div',{staticClass:"text-center text-success my-2"},[_c('b-spinner',{staticClass:"align-middle"})],1)]):_c('b-form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[_c('b-row',{staticClass:"align-items-end"},[_c('b-col',{attrs:{"cols":"12"}},[_c('LocaleTabs',{attrs:{"errors":_vm.formErrors,"name":"title"},scopedSlots:_vm._u([_vm._l((_vm.languages),function(i,k,idx){return {key:("lang" + idx),fn:function(slotScope){return [_c('ValidationProvider',{key:idx,class:slotScope.selectedTab!==idx?'hidden':'',attrs:{"vid":("title." + k),"name":_vm.$t('general.field_lang', { field: _vm.$t('labels.title'), lang: _vm.$t(("general." + (i.toLocaleLowerCase()))) }),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('general.field_lang', { field: _vm.$t('labels.title'), lang: _vm.$t(("general." + (i.toLocaleLowerCase()))) })}},[_c('b-form-input',{attrs:{"placeholder":_vm.$t('general.field_lang', { field: _vm.$t('labels.title'), lang: _vm.$t(("general." + (i.toLocaleLowerCase()))) })},model:{value:(_vm.form.name[k]),callback:function ($$v) {_vm.$set(_vm.form.name, k, $$v)},expression:"form.name[k]"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})]}}})],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"vid":"type","name":_vm.$t('labels.type'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('labels.type')}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.layout.direction,"label":"name","options":_vm.types_list,"reduce":function (item) { return item.id; },"placeholder":_vm.$t('select')},on:{"input":function($event){return _vm.onChangeSelect("type")}},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('No options'))+" ")]},proxy:true}],null,true),model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),(_vm.details)?_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"vid":"details","name":_vm.$t('details'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('details')}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.layout.direction,"label":"description","multiple":"","options":_vm.details,"reduce":function (item) { return item.id; },"placeholder":_vm.$t('select')},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('No options'))+" ")]},proxy:true}],null,true),model:{value:(_vm.form.details),callback:function ($$v) {_vm.$set(_vm.form, "details", $$v)},expression:"form.details"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,117529881)})],1):_vm._e(),_c('b-col',{attrs:{"md":"6","cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('price'),"vid":"price","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('price')}},[_c('b-form-input',{attrs:{"placeholder":_vm.$t('price'),"autocomplete":"off","type":"number"},model:{value:(_vm.form.price),callback:function ($$v) {_vm.$set(_vm.form, "price", $$v)},expression:"form.price"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6","cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('user_id_symbol'),"vid":"user_id_symbol","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('user_id_symbol')}},[_c('b-form-input',{attrs:{"placeholder":_vm.$t('user_id_symbol'),"autocomplete":"off","type":"text"},model:{value:(_vm.form.user_id_symbol),callback:function ($$v) {_vm.$set(_vm.form, "user_id_symbol", $$v)},expression:"form.user_id_symbol"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6","cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('number_of_days'),"vid":"number_of_days","rules":{
                       required: true,
                       numeric: true,
                     }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                     var errors = ref.errors;
return [_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.$t('number_of_days')))])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"placeholder":_vm.$t('number_of_days'),"autocomplete":"off","type":"number"},model:{value:(_vm.form.number_of_days),callback:function ($$v) {_vm.$set(_vm.form, "number_of_days", $$v)},expression:"form.number_of_days"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6","cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('sort'),"vid":"sort","rules":{
                       numeric: true,
                     }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                     var errors = ref.errors;
return [_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.$t('sort')))])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"placeholder":_vm.$t('sort'),"autocomplete":"off","type":"number"},model:{value:(_vm.form.sort),callback:function ($$v) {_vm.$set(_vm.form, "sort", $$v)},expression:"form.sort"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"vid":"icon","name":_vm.$t('labels.icon'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                     var errors = ref.errors;
return [_c('ImagePreview',{attrs:{"label":_vm.$t('labels.icon')},model:{value:(_vm.icon),callback:function ($$v) {_vm.icon=$$v},expression:"icon"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('hr',{staticClass:"my-1"})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"vid":"default_map_icon","name":_vm.$t('default_map_icon')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                     var errors = ref.errors;
return [_c('ImagePreview',{attrs:{"label":_vm.$t('default_map_icon')},model:{value:(_vm.default_map_icon),callback:function ($$v) {_vm.default_map_icon=$$v},expression:"default_map_icon"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('hr',{staticClass:"my-1"})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"vid":"selected_map_icon","name":_vm.$t('selected_map_icon')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                     var errors = ref.errors;
return [_c('ImagePreview',{attrs:{"label":_vm.$t('selected_map_icon')},model:{value:(_vm.selected_map_icon),callback:function ($$v) {_vm.selected_map_icon=$$v},expression:"selected_map_icon"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('hr',{staticClass:"my-1"})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"vid":"not_selected_map_icon","name":_vm.$t('not_selected_map_icon')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                     var errors = ref.errors;
return [_c('ImagePreview',{attrs:{"label":_vm.$t('not_selected_map_icon')},model:{value:(_vm.not_selected_map_icon),callback:function ($$v) {_vm.not_selected_map_icon=$$v},expression:"not_selected_map_icon"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('hr',{staticClass:"my-1"})],1),_c('b-col',{attrs:{"cols":"12 text-right"}},[_c('LoadingButton'),_c('b-button',{attrs:{"type":"reset","variant":"outline-secondary"}},[_vm._v(" "+_vm._s(_vm.$t('labels.reset'))+" ")])],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }