import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import dashboard from './routes/dashboard'
import pages from './routes/pages'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    }
  },
  routes: [
    ...dashboard,
    ...pages,
    {
      path: '/',
      redirect: { name: 'dashboard-analytics' },
    },
    {
      path: '/change-password',
      name: 'change-password',
      component: () => import('@/views/profile/change-password/form.vue'),
      meta: {
        resource: 'change-password',
      },
    },
    {
      path: '/users',
      name: 'users',
      component: () => import('@/views/users/list.vue'),
    },
    {
      path: '/users/add',
      name: 'add-user',
      component: () => import('@/views/users/form.vue'),
      meta: {
        resource: 'users',
        pageTitle: 'Add User',
        breadcrumb: [
          {
            to: { name: 'users' },
            text: 'Users',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/users/:resourceId',
      name: 'edit-user',
      component: () => import('@/views/users/form.vue'),
      meta: {
        resource: 'users',
        pageTitle: 'Edit User',
        breadcrumb: [
          {
            to: { name: 'users' },
            text: 'Users',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },

    {
      path: '/roles',
      name: 'roles',
      component: () => import('@/views/roles/list.vue'),
    },
    {
      path: '/roles/add',
      name: 'add-role',
      component: () => import('@/views/roles/form.vue'),
      meta: {
        resource: 'roles',
        pageTitle: 'Add Role',
        breadcrumb: [
          {
            to: { name: 'roles' },
            text: 'Roles',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/roles/:resourceId',
      name: 'edit-role',
      component: () => import('@/views/roles/form.vue'),
      meta: {
        resource: 'roles',
        pageTitle: 'Edit Role',
        breadcrumb: [
          {
            to: { name: 'roles' },
            text: 'Roles',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/roles/:resourceId',
      name: 'roles-details',
      component: () => import('@/views/roles/details.vue'),
      meta: {
        resource: 'roles',
        pageTitle: 'Role Details',
        breadcrumb: [
          {
            to: { name: 'roles' },
            text: 'Roles',
          },
          {
            text: 'Details',
            active: true,
          },
        ],
      },
    },

    {
      path: '/real-state-type',
      name: 'real-state-type',
      component: () => import('@/views/real-estate/types/list.vue'),
    },
    {
      path: '/real-state-type/add',
      name: 'add-real-state-type',
      component: () => import('@/views/real-estate/types/form.vue'),
      meta: {
        resource: 'real-estate-config/types',
        pageTitle: 'Add Real Estate Type',
        breadcrumb: [
          {
            to: { name: 'real-state-type' },
            text: 'Real Estate Type',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/real-state-type/:resourceId',
      name: 'edit-real-state-type',
      component: () => import('@/views/real-estate/types/form.vue'),
      meta: {
        resource: 'real-estate-config/types',
        pageTitle: 'Edit Real Estate Type',
        breadcrumb: [
          {
            to: { name: 'real-state-type' },
            text: 'Real Estate Type',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },

    {
      path: '/type-category',
      name: 'type-category',
      component: () => import('@/views/real-estate/types-categories/list.vue'),
    },
    {
      path: '/type-category/add',
      name: 'add-type-category',
      component: () => import('@/views/real-estate/types-categories/form.vue'),
      meta: {
        resource: 'type-category',
        pageTitle: 'Type Category',
        breadcrumb: [
          {
            to: { name: 'type-category' },
            text: 'type category',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/type-category/:resourceId',
      name: 'edit-type-category',
      component: () => import('@/views/real-estate/types-categories/form.vue'),
      meta: {
        resource: 'type-category',
        pageTitle: 'Type Category',
        breadcrumb: [
          {
            to: { name: 'type-category' },
            text: 'Type Category',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },

    {
      path: '/type-parent-category',
      name: 'type-parent-category',
      component: () => import('@/views/real-estate/types-parent-categories/list.vue'),
    },
    {
      path: '/type-parent-category/add',
      name: 'add-type-parent-category',
      component: () => import('@/views/real-estate/types-parent-categories/form.vue'),
      meta: {
        resource: 'type-parent-category',
        pageTitle: 'Type Parent Category',
        breadcrumb: [
          {
            to: { name: 'type-parent-category' },
            text: 'type parent category',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/type-parent-category/:resourceId',
      name: 'edit-type-parent-category',
      component: () => import('@/views/real-estate/types-parent-categories/form.vue'),
      meta: {
        resource: 'type-parent-category',
        pageTitle: 'Type Parent Category',
        breadcrumb: [
          {
            to: { name: 'type-parent-category' },
            text: 'Type Parent Category',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },

    {
      path: '/real-estate-leave-on-us-private',
      name: 'real-estate-leave-on-us-private',
      component: () => import('@/views/real-estate/real-estate-leave-on-us/list.vue'),
    },

    {
      path: '/real-estate-leave-on-us-private/:resourceId',
      name: 'edit-real-estate-leave-on-us-private',
      component: () => import('@/views/real-estate/real-estate-leave-on-us/form.vue'),
      meta: {
        resource: 'real-estate-leave-on-us',
        pageTitle: 'real estate leave on us',
        breadcrumb: [
          {
            to: { name: 'real-estate-leave-on-us-private' },
            text: 'real estate leave on us',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/real-estate-leave-on-us-private/:resourceId/show',
      name: 'details-real-estate-leave-on-us-private',
      meta: {
        resource: 'real-estate-leave-on-us',
        pageTitle: 'real-estate-details',
        breadcrumb: [
          {
            to: { name: 'real-estate-leave-on-us-private' },
            text: 'real estate leave on us',
          },
          {
            text: 'real-estate-details',
            active: true,
          },
        ],
      },
      component: () => import('@/views/real-estate/real-estate-leave-on-us/show.vue'),
    },


    //
    {
      path: '/real-estate-leave-on-us',
      name: 'real-estate-leave-on-us',
      component: () => import('@/views/real-estate/real-estate-leave-on-us/list.vue'),
    },

    {
      path: '/real-estate-leave-on-us/:resourceId',
      name: 'edit-real-estate-leave-on-us',
      component: () => import('@/views/real-estate/real-estate-leave-on-us/form.vue'),
      meta: {
        resource: 'real-estate-leave-on-us',
        pageTitle: 'real estate leave on us',
        breadcrumb: [
          {
            to: { name: 'real-estate-leave-on-us' },
            text: 'real estate leave on us',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/real-estate-leave-on-us/:resourceId/show',
      name: 'details-real-estate-leave-on-us',
      meta: {
        resource: 'real-estate-leave-on-us',
        pageTitle: 'real-estate-details',
        breadcrumb: [
          {
            to: { name: 'real-estate-leave-on-us' },
            text: 'real estate leave on us',
          },
          {
            text: 'real-estate-details',
            active: true,
          },
        ],
      },
      component: () => import('@/views/real-estate/real-estate-leave-on-us/show.vue'),
    },


    {
      path: '/marketing-request',
      name: 'marketing-request',
      component: () => import('@/views/real-estate/marketing-request/list.vue'),
    },

    {
      path: '/marketing-request/:resourceId',
      name: 'edit-marketing-request',
      component: () => import('@/views/real-estate/real-estate-leave-on-us/form.vue'),
      meta: {
        resource: 'marketing-request',
        pageTitle: 'marketing request',
        breadcrumb: [
          {
            to: { name: 'marketing-request' },
            text: 'marketing request',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/marketing-request/:resourceId/show',
      name: 'details-marketing-request',
      meta: {
        resource: 'marketing-request',
        pageTitle: 'marketing-request-details',
        breadcrumb: [
          {
            to: { name: 'marketing-request' },
            text: 'marketing request',
          },
          {
            text: 'marketing-request-details',
            active: true,
          },
        ],
      },
      component: () => import('@/views/real-estate/real-estate-leave-on-us/show.vue'),
    },

    {
      path: '/field',
      name: 'field',
      component: () => import('@/views/field/list.vue'),
    },
    {
      path: '/field/add',
      name: 'add-field',
      component: () => import('@/views/field/form.vue'),
      meta: {
        resource: 'field',
        pageTitle: 'Field',
        breadcrumb: [
          {
            to: { name: 'field' },
            text: 'Field',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/field/:resourceId',
      name: 'edit-field',
      component: () => import('@/views/field/form.vue'),
      meta: {
        resource: 'field',
        pageTitle: 'Field',
        breadcrumb: [
          {
            to: { name: 'field' },
            text: 'Field',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },

    {
      path: '/real-state-status-logs',
      name: 'real-state-status-logs',
      component: () => import('@/views/logs/status-list.vue'),
    },
    {
      path: '/real-state-deleted-logs',
      name: 'real-state-deleted-logs',
      component: () => import('@/views/logs/deleted-list.vue'),
    },

    {
      path: '/real-state-updated-logs',
      name: 'real-state-updated-logs',
      component: () => import('@/views/logs/updated-list.vue'),
    },

    {
      path: '/real-state-logs/:resourceId/show',
      name: 'details-real-state-logs',
      meta: {
        resource: 'real-estate',
        pageTitle: 'real-estates',
        breadcrumb: [
          {
             text: 'real-estates',
          },
          {
            text: 'real-estate-details',
            active: true,
          },
        ],
      },
      component: () => import('@/views/real-estate/show.vue'),
    },

    {
      path: '/package',
      name: 'package',
      component: () => import('@/views/packages/list.vue'),
    },
    {
      path: '/package/add',
      name: 'add-package',
      component: () => import('@/views/packages/form.vue'),
      meta: {
        resource: 'package',
        pageTitle: 'Add Package',
        breadcrumb: [
          {
            to: { name: 'package' },
            text: 'package',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/package/:resourceId',
      name: 'edit-package',
      component: () => import('@/views/packages/form.vue'),
      meta: {
        resource: 'package',
        pageTitle: 'Edit Package',
        breadcrumb: [
          {
            to: { name: 'package' },
            text: 'package',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },

    {
      path: '/package-detail',
      name: 'package-detail',
      component: () => import('@/views/package-details/list.vue'),
    },
    {
      path: '/package-detail/add',
      name: 'add-package-detail',
      component: () => import('@/views/package-details/form.vue'),
      meta: {
        resource: 'package-detail',
        pageTitle: 'Add Package Detail',
        breadcrumb: [
          {
            to: { name: 'package-detail' },
            text: 'package-detail',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/package-detail/:resourceId',
      name: 'edit-package-detail',
      component: () => import('@/views/package-details/form.vue'),
      meta: {
        resource: 'package-detail',
        pageTitle: 'Edit Package Detail',
        breadcrumb: [
          {
            to: { name: 'package-detail' },
            text: 'package-detail',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },

    {
      path: '/payment-method',
      name: 'payment-method',
      component: () => import('@/views/payment-method/list.vue'),
    },
    {
      path: '/payment-method/add',
      name: 'add-payment-method',
      component: () => import('@/views/payment-method/form.vue'),
      meta: {
        resource: 'payment-method',
        pageTitle: 'Add Payment Method',
        breadcrumb: [
          {
            to: { name: 'payment-method' },
            text: 'payment-method',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/payment-method/:resourceId',
      name: 'edit-payment-method',
      component: () => import('@/views/payment-method/form.vue'),
      meta: {
        resource: 'payment-method',
        pageTitle: 'Edit Payment Method',
        breadcrumb: [
          {
            to: { name: 'payment-method' },
            text: 'payment-method',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },

    {
      path: '/real-state-usage',
      name: 'real-state-usage',
      component: () => import('@/views/real-estate/usages/list.vue'),
    },
    {
      path: '/real-state-usage/add',
      name: 'add-real-estate-usage',
      component: () => import('@/views/real-estate/usages/form.vue'),
      meta: {
        resource: 'real-estate-usage',
        pageTitle: 'Add Real Estate Usage',
        breadcrumb: [
          {
            to: { name: 'real-state-usage' },
            text: 'Real Estate Usage',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/real-state-usage/:resourceId',
      name: 'edit-real-estate-usage',
      component: () => import('@/views/real-estate/usages/form.vue'),
      meta: {
        resource: 'real-estate-usage',
        pageTitle: 'Edit Real Estate Usage',
        breadcrumb: [
          {
            to: { name: 'real-state-usage' },
            text: 'Real Estate Usage',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },

    {
      path: '/amenities',
      name: 'amenities',
      component: () => import('@/views/amenities/list.vue'),
    },
    {
      path: '/amenities/add',
      name: 'add-amenities',
      component: () => import('@/views/amenities/form.vue'),
      meta: {
        resource: 'real-estate-config/amenities',
        pageTitle: 'Add Real Estate Amenities',
        breadcrumb: [
          {
            to: { name: 'amenities' },
            text: 'Real Estate Amenities',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/amenities/:resourceId',
      name: 'edit-amenities',
      component: () => import('@/views/amenities/form.vue'),
      meta: {
        resource: 'real-estate-config/amenities',
        pageTitle: 'Edit Real Estate Amenities',
        breadcrumb: [
          {
            to: { name: 'amenities' },
            text: 'Real Estate Amenities',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/newsletter',
      name: 'newsletter',
      component: () => import('@/views/newsletter/list.vue'),
    },
    {
      path: '/newsletter/add',
      name: 'add-newsletter',
      component: () => import('@/views/newsletter/form.vue'),
      meta: {
        resource: 'newsletter',
        pageTitle: 'Add Newsletter',
        breadcrumb: [
          {
            to: { name: 'newsletter' },
            text: 'News Letter',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/newsletter/:resourceId',
      name: 'edit-newsletter',
      component: () => import('@/views/newsletter/form.vue'),
      meta: {
        resource: 'newsletter',
        pageTitle: 'Edit Newsletter',
        breadcrumb: [
          {
            to: { name: 'newsletter' },
            text: 'News Letter',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },



    {
      path: '/notifications',
      name: 'notifications',
      component: () => import('@/views/notifications/list.vue'),
    },
    {
      path: '/notifications/add',
      name: 'add-notifications',
      component: () => import('@/views/notifications/form.vue'),
      meta: {
        resource: 'notifications',
        pageTitle: 'Add Notifications',
        breadcrumb: [
          {
            to: { name: 'notifications' },
            text: 'notifications',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },

    {
      path: '/notification-messages',
      name: 'notification-messages',
      component: () => import('@/views/notification-messages/list.vue'),
    },

    {
      path: '/notification-messages/:resourceId',
      name: 'edit-notification-messages',
      component: () => import('@/views/notification-messages/form.vue'),
      meta: {
        resource: 'notification-messages',
        pageTitle: 'Edit Notification Messages',
        breadcrumb: [
          {
            to: { name: 'notification-messages' },
            text: 'notification-messages',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },

    {
      path: '/faqs',
      name: 'faqs',
      component: () => import('@/views/faqs/list.vue'),
    },
    {
      path: '/faqs/add',
      name: 'add-faq',
      component: () => import('@/views/faqs/form.vue'),
      meta: {
        resource: 'faqs',
        pageTitle: 'Add FAQ',
        breadcrumb: [
          {
            to: { name: 'faqs' },
            text: 'FAQ',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/faqs/:resourceId',
      name: 'edit-faq',
      component: () => import('@/views/faqs/form.vue'),
      meta: {
        resource: 'faqs',
        pageTitle: 'Edit FAQ',
        breadcrumb: [
          {
            to: { name: 'faqs' },
            text: 'FAQ',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/contact-us',
      name: 'contact-us',
      component: () => import('@/views/contact-us/list.vue'),
    },
    {
      path: '/contact-us/:resourceId/show',
      name: 'contact-details',
      meta: {
        resource: 'contact-us',
        pageTitle: 'contact-details',
        breadcrumb: [
          {
            to: { name: 'contact-us' },
            text: 'contact-us',
          },
          {
            text: 'contact-details',
            active: true,
          },
        ],
      },
      component: () => import('@/views/contact-us/show.vue'),
    },
    {
      path: '/contact-us/:resourceId',
      name: 'edit-contact-us',
      component: () => import('@/views/contact-us/form.vue'),
      meta: {
        resource: 'contact-us',
        pageTitle: 'Edit contact-us',
        breadcrumb: [
          {
            to: { name: 'contact-us' },
            text: 'contact-us',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/conflicts',
      name: 'conflicts',
      component: () => import('@/views/conflicts/list.vue'),
    },
    {
      path: '/conflicts/:resourceId/show',
      name: 'conflict-details',
      meta: {
        resource: 'conflicts',
        pageTitle: 'conflict-details',
        breadcrumb: [
          {
            to: { name: 'conflicts' },
            text: 'conflicts',
          },
          {
            text: 'conflict-details',
            active: true,
          },
        ],
      },
      component: () => import('@/views/conflicts/show.vue'),
    },

    {
      path: '/settings',
      name: 'settings',
      meta: {
        resource: 'settings',
      },
      component: () => import('@/views/settings/form.vue'),
    },

    {
      path: '/blogs',
      name: 'blogs',
      component: () => import('@/views/blogs/list.vue'),
    },
    {
      path: '/blogs/add',
      name: 'add-blog',
      component: () => import('@/views/blogs/form.vue'),
      meta: {
        resource: 'blogs',
        pageTitle: 'Add News',
        breadcrumb: [
          {
            to: { name: 'blogs' },
            text: 'News',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/blogs/:resourceId',
      name: 'edit-blog',
      component: () => import('@/views/blogs/form.vue'),
      meta: {
        resource: 'blogs',
        pageTitle: 'Edit News',
        breadcrumb: [
          {
            to: { name: 'blogs' },
            text: 'News',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/news-views/:blog_id',
      name: 'news-views',
      component: () => import('@/views/blogs/news-views.vue'),
      meta: {
        resource: 'customer-blog-views',
        pageTitle: 'News Views',
        breadcrumb: [
          {
            to: { name: 'blogs' },
            text: 'News',
          },
          {
            text: 'News Views',
            active: true,
          },
        ],
      },
    },
    {
      path: '/news-ratings/:blog_id',
      name: 'news-ratings',
      component: () => import('@/views/blogs/news-ratings.vue'),
      meta: {
        resource: 'blogs-ratings',
        pageTitle: 'News Ratings',
        breadcrumb: [
          {
            to: { name: 'blogs' },
            text: 'News',
          },
          {
            text: 'News Ratings',
            active: true,
          },
        ],
      },
    },
    //
    // {
    //   path: '/blog-categories',
    //   name: 'blog-categories',
    //   component: () => import('@/views/blog-categories/list.vue'),
    // },
    // {
    //   path: '/blog-categories/add',
    //   name: 'add-blog-category',
    //   component: () => import('@/views/blog-categories/form.vue'),
    //   meta: {
    //     resource: 'blog-categories',
    //     pageTitle: 'Add News Category',
    //     breadcrumb: [
    //       {
    //         to: { name: 'blog-categories' },
    //         text: 'News Category',
    //       },
    //       {
    //         text: 'Create',
    //         active: true,
    //       },
    //     ],
    //   },
    // },
    // {
    //   path: '/blog-categories/:resourceId',
    //   name: 'edit-blog-category',
    //   component: () => import('@/views/blog-categories/form.vue'),
    //   meta: {
    //     resource: 'blog-categories',
    //     pageTitle: 'Edit News Category',
    //     breadcrumb: [
    //       {
    //         to: { name: 'blog-categories' },
    //         text: 'News Category',
    //       },
    //       {
    //         text: 'Edit',
    //         active: true,
    //       },
    //     ],
    //   },
    // },
    {
      path: '/pages-builder',
      name: 'pages-builder',
      component: () => import('@/views/page-builder/list.vue'),
    },
    {
      path: '/pages-builder/add',
      name: 'add-page-builder',
      component: () => import('@/views/page-builder/form.vue'),
      meta: {
        resource: 'pages-builder',
        pageTitle: 'Add Page',
        breadcrumb: [
          {
            to: { name: 'pages-builder' },
            text: 'Pages',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/pages-builder/:resourceId',
      name: 'edit-page-builder',
      component: () => import('@/views/page-builder/form.vue'),
      meta: {
        resource: 'pages-builder',
        pageTitle: 'Edit Page',
        breadcrumb: [
          {
            to: { name: 'pages-builder' },
            text: 'Pages',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/pages-builder/:resourceId/show',
      name: 'page-details',
      meta: {
        resource: 'pages-builder',
        pageTitle: 'page-details',
        breadcrumb: [
          {
            to: { name: 'pages-builder' },
            text: 'Pages',
          },
          {
            text: 'page-details',
            active: true,
          },
        ],
      },
      component: () => import('@/views/page-builder/show.vue'),
    },
    {
      path: '/advertisements',
      name: 'advertisements',
      component: () => import('@/views/advertisements/list.vue'),
    },
    {
      path: '/advertisements/add',
      name: 'add-advertisement',
      component: () => import('@/views/advertisements/form.vue'),
      meta: {
        resource: 'advertisements',
        pageTitle: 'Add Advertisement',
        breadcrumb: [
          {
            to: { name: 'advertisements' },
            text: 'Advertisements',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/advertisements/:resourceId',
      name: 'edit-advertisement',
      component: () => import('@/views/advertisements/form.vue'),
      meta: {
        resource: 'advertisements',
        pageTitle: 'Edit Advertisements',
        breadcrumb: [
          {
            to: { name: 'advertisements' },
            text: 'Advertisements',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },

    {
      path: '/ads-category',
      name: 'advertisements/categories',
      component: () => import('@/views/ads-category/list.vue'),
    },
    {
      path: '/ads-category/add',
      name: 'add-ads-category',
      component: () => import('@/views/ads-category/form.vue'),
      meta: {
        resource: 'advertisements/categories',
        pageTitle: 'Add Ads Category',
        breadcrumb: [
          {
            to: { name: 'advertisements/categories' },
            text: 'Ads Category',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/ads-category/:resourceId',
      name: 'edit-ads-category',
      component: () => import('@/views/ads-category/form.vue'),
      meta: {
        resource: 'advertisements/categories',
        pageTitle: 'Edit Ads Category',
        breadcrumb: [
          {
            to: { name: 'advertisements/categories' },
            text: 'Ads Category',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },

    // {
    //   path: '/countries',
    //   name: 'countries',
    //   component: () => import('@/views/countries/list.vue'),
    // },
    // {
    //   path: '/countries/add',
    //   name: 'add-country',
    //   component: () => import('@/views/countries/form.vue'),
    //   meta: {
    //     resource: 'countries',
    //     pageTitle: 'Add Country',
    //     breadcrumb: [
    //       {
    //         to: { name: 'countries' },
    //         text: 'Countries',
    //       },
    //       {
    //         text: 'Create',
    //         active: true,
    //       },
    //     ],
    //   },
    // },
    // {
    //   path: '/countries/:resourceId',
    //   name: 'edit-country',
    //   component: () => import('@/views/countries/form.vue'),
    //   meta: {
    //     resource: 'countries',
    //     pageTitle: 'Edit Country',
    //     breadcrumb: [
    //       {
    //         to: { name: 'countries' },
    //         text: 'Countries',
    //       },
    //       {
    //         text: 'Edit',
    //         active: true,
    //       },
    //     ],
    //   },
    // },
    {
      path: '/regions',
      name: 'regions',
      component: () => import('@/views/countries/regions/list.vue'),
    },
    {
      path: '/regions/add',
      name: 'add-region',
      meta: {
        resource: 'regions',
        pageTitle: 'Add Regions',
        type: 'add',
        breadcrumb: [
          {
            to: { name: 'regions' },
            text: 'Regions',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
      component: () => import('@/views/countries/regions/form.vue'),
    },
    {
      path: '/regions/:resourceId/edit',
      name: 'edit-region',
      meta: {
        resource: 'regions',
        pageTitle: 'Edit Regions',
        type: 'edit',
        breadcrumb: [
          {
            to: { name: 'regions' },
            text: 'Regions',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
      component: () => import('@/views/countries/regions/form.vue'),
    },
    {
      path: '/cities',
      name: 'cities',
      component: () => import('@/views/countries/cities/list.vue'),
    },
    {
      path: '/cities/add',
      name: 'add-city',
      meta: {
        resource: 'cities',
        pageTitle: 'Add Cities',
        type: 'add',
        breadcrumb: [
          {
            to: { name: 'cities' },
            text: 'Cities',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
      component: () => import('@/views/countries/cities/form.vue'),
    },
    {
      path: '/cities/:resourceId/edit',
      name: 'edit-city',
      meta: {
        resource: 'cities',
        pageTitle: 'Edit Cities',
        type: 'edit',
        breadcrumb: [
          {
            to: { name: 'cities' },
            text: 'Cities',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
      component: () => import('@/views/countries/cities/form.vue'),
    },

    {
      path: '/directions',
      name: 'directions',
      component: () => import('@/views/countries/directions/list.vue'),
    },
    {
      path: '/directions/add',
      name: 'add-direction',
      meta: {
        resource: 'directions',
        pageTitle: 'Add Directions',
        type: 'add',
        breadcrumb: [
          {
            to: { name: 'directions' },
            text: 'directions',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
      component: () => import('@/views/countries/directions/form.vue'),
    },
    {
      path: '/directions/:resourceId/edit',
      name: 'edit-direction',
      meta: {
        resource: 'directions',
        pageTitle: 'Edit Directions',
        type: 'edit',
        breadcrumb: [
          {
            to: { name: 'directions' },
            text: 'directions',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
      component: () => import('@/views/countries/directions/form.vue'),
    },
    {
      path: '/districts',
      name: 'districts',
      component: () => import('@/views/countries/districts/list.vue'),
    },
    {
      path: '/districts/add',
      name: 'add-district',
      meta: {
        resource: 'districts',
        pageTitle: 'Add Districts',
        type: 'add',
        breadcrumb: [
          {
            to: { name: 'districts' },
            text: 'Districts',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
      component: () => import('@/views/countries/districts/form.vue'),
    },
    {
      path: '/districts/:resourceId/edit',
      name: 'edit-district',
      meta: {
        resource: 'districts',
        pageTitle: 'Edit Districts',
        type: 'edit',
        breadcrumb: [
          {
            to: { name: 'districts' },
            text: 'Districts',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
      component: () => import('@/views/countries/districts/form.vue'),
    },
    {
      path: '/districts/:resourceId/view',
      name: 'view-district',
      meta: {
        resource: 'districts',
        pageTitle: 'View Districts',
        type: 'details',
        breadcrumb: [
          {
            to: { name: 'districts' },
            text: 'Districts',
          },
          {
            text: 'details',
            active: true,
          },
        ],
      },
      component: () => import('@/views/countries/districts/view.vue'),
    },
    {
      path: '/customers',
      name: 'customers',
       component: () => import('@/views/customers/list.vue'),
    },
    {
      path: '/customers/add',
      name: 'add-customer',
      meta: {
        resource: 'customers',
        pageTitle: 'Add customer',
        type: 'add',
        breadcrumb: [
          {
            to: { name: 'customers' },
            text: 'customers',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
      component: () => import('@/views/customers/form.vue'),
    },
    {
      path: '/customers/:resourceId/edit',
      name: 'edit-customer',
      meta: {
        resource: 'customers',
        pageTitle: 'Edit customers',
        type: 'edit',
        breadcrumb: [
          {
            to: { name: 'customers' },
            text: 'customers',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
      component: () => import('@/views/customers/form.vue'),
    },
    {
      path: '/favorite-ads/:customer_id',
      name: 'favorite-ads',
      meta: {
        resource: 'real-estate/likes',
        pageTitle: 'Favorite Ads',
        breadcrumb: [
          {
            to: { name: 'customers' },
            text: 'customers',
          },
          {
            text: 'Favorite Ads',
            active: true,
          },
        ],
      },
      component: () => import('@/views/customers/favorite-ads/list.vue'),
    },
    {
      path: '/customer-search/:customer_id',
      name: 'customer-search',
      meta: {
        pageTitle: 'Customer Search',
        breadcrumb: [
          {
            to: { name: 'customers' },
            text: 'customers',
          },
          {
            text: 'Customer Search',
            active: true,
          },
        ],
      },
      component: () => import('@/views/customers/search-archive/list.vue'),
    },
    {
      path: '/customer-views/:customer_id',
      name: 'customer-views',
      meta: {
        resource: 'real-estate-views',
        pageTitle: 'Customer Views',
        breadcrumb: [
          {
            to: { name: 'customers' },
            text: 'customers',
          },
          {
            text: 'Customer Views',
            active: true,
          },
        ],
      },
      component: () => import('@/views/customers/views-archive/list.vue'),
    },
    {
      path: '/advertiser-ratings/:customer_id',
      name: 'advertiser-ratings',
      meta: {
        resource: 'advertiser-ratings',
        pageTitle: 'Advertiser Ratings',
        breadcrumb: [
          {
            to: { name: 'customers' },
            text: 'customers',
          },
          {
            text: 'Advertiser Ratings',
            active: true,
          },
        ],
      },
      component: () => import('@/views/customers/advertiser-ratings/list.vue'),
    },
    // {
    //   path: '/comparisons-logs',
    //   name: 'comparisons-logs',
    //   meta: {
    //     resource: 'comparisons-logs',
    //     pageTitle: 'comparisons-logs',
    //     breadcrumb: [
    //       {
    //         to: { name: 'customers' },
    //         text: 'customers',
    //       },
    //       {
    //         text: 'comparison logs',
    //         active: true,
    //       },
    //     ],
    //   },
    //   component: () => import('@/views/customers/comparisons-logs/list.vue'),
    // },
    {
      path: '/real-estate-options',
      name: 'real-estate-options',
      component: () => import('@/views/real-estate/real-estate-options/list.vue'),
    },
    {
      path: '/real-estate-not-licensed',
      name: 'real-estate-not-licensed',
      component: () => import('@/views/real-estate/real-estate-not-licensed/list.vue'),
    },
    {
      path: '/real-estate-private',
      name: 'real-estate-private',
      component: () => import('@/views/real-estate/real-estate-private/list.vue'),
    },

    {
      path: '/real-estate-licensed-private/add',
      name: 'add-real-estate-licensed-private',
      meta: {
        resource: 'real-estate',
        pageTitle: 'add-real-estate-licensed-private',
        breadcrumb: [
          {
            to: { name: 'real-estate-private' },
            text: 'real-estates',
          },
          {
            text: 'real-estate-licensed',
            active: true,
          },
        ],
      },
      component: () => import('@/views/real-estate/form.vue'),
    },

    {
      path: '/real-estate-private/add',
      name: 'add-real-estate-private',
      meta: {
        resource: 'real-estate',
        pageTitle: 'add-real-estate-private',
        breadcrumb: [
          {
            to: { name: 'real-estate-private' },
            text: 'real-estates',
          },
          {
            text: 'real-estate-private',
            active: true,
          },
        ],
      },
      component: () => import('@/views/real-estate/form.vue'),
    },
    {
      path: '/real-estate-private/:resourceId/edit',
      name: 'edit-real-estate-private',
      meta: {
        resource: 'real-estate',
        pageTitle: 'edit-real-estate-private',
        breadcrumb: [
          {
            to: { name: 'real-estate-private' },
            text: 'real-estates',
          },
          {
            text: 'real-estate-private',
            active: true,
          },
        ],
      },
      component: () => import('@/views/real-estate/form.vue'),
    },
    {
      path: '/real-estate-private/:resourceId/show',
      name: 'details-real-estate-private',
      meta: {
        resource: 'real-estate',
        pageTitle: 'real-estate-private-details',
        breadcrumb: [
          {
            to: { name: 'real-estate-private' },
            text: 'real-estates',
          },
          {
            text: 'real-estate-details',
            active: true,
          },
        ],
      },
      component: () => import('@/views/real-estate/show.vue'),
    },
    {
      path: '/real-estate-private/:resourceId/images/show',
      name: 'show-images-real-estate-private',
      meta: {
        resource: 'real-estate',
        pageTitle: 'real-estate-private-images',
        breadcrumb: [
          {
            to: { name: 'real-estate-private' },
            text: 'real-estates',
          },
          {
            text: 'real-estate-images',
            active: true,
          },
        ],
      },
      component: () => import('@/views/real-estate/images/details.vue'),
    },
    {
      path: '/real-estate-private/:resourceId/ratings/:rateable_id',
      name: 'real-estate-private-ratings',
      meta: {
        resource: 'real-estate-ratings',
        pageTitle: 'real-estate-private-ratings',
        breadcrumb: [
          {
            to: { name: 'real-estate-private' },
            text: 'real-estates',
          },
          {
            text: 'real-estate-private',
            active: true,
          },
        ],
      },
      component: () => import('@/views/real-estate/ratings/details.vue'),
    },


    ///
    {
      path: '/real-estate-licensed',
      name: 'real-estate-licensed',
      component: () => import('@/views/real-estate/real-estate-licensed/list.vue'),
    },
    ////
    {
      path: '/real-estate-licensed/add',
      name: 'add-real-estate-licensed',
      meta: {
        resource: 'real-estate',
        pageTitle: 'add-real-estate-licensed',
        breadcrumb: [
          {
            to: { name: 'real-estate-licensed' },
            text: 'real-estates',
          },
          {
            text: 'real-estate-licensed',
            active: true,
          },
        ],
      },
      component: () => import('@/views/real-estate/form.vue'),
    },
    {
      path: '/real-estate-licensed/:resourceId/edit',
      name: 'edit-real-estate-licensed',
      meta: {
        resource: 'real-estate',
        pageTitle: 'edit-real-estate-licensed',
        breadcrumb: [
          {
            to: { name: 'real-estate-licensed' },
            text: 'real-estates',
          },
          {
            text: 'real-estate-licensed',
            active: true,
          },
        ],
      },
      component: () => import('@/views/real-estate/form.vue'),
    },
    {
      path: '/real-estate-licensed/:resourceId/show',
      name: 'details-real-estate-licensed',
      meta: {
        resource: 'real-estate',
        pageTitle: 'real-estate-licensed-details',
        breadcrumb: [
          {
            to: { name: 'real-estate-licensed' },
            text: 'real-estates',
          },
          {
            text: 'real-estate-details',
            active: true,
          },
        ],
      },
      component: () => import('@/views/real-estate/show.vue'),
    },
    {
      path: '/real-estate-licensed/:resourceId/images/show',
      name: 'show-images-real-estate-licensed',
      meta: {
        resource: 'real-estate',
        pageTitle: 'real-estate-licensed-images',
        breadcrumb: [
          {
            to: { name: 'real-estate-licensed' },
            text: 'real-estates',
          },
          {
            text: 'real-estate-images',
            active: true,
          },
        ],
      },
      component: () => import('@/views/real-estate/images/details.vue'),
    },
    {
      path: '/real-estate-licensed/:resourceId/ratings/:rateable_id',
      name: 'real-estate-licensed-ratings',
      meta: {
        resource: 'real-estate-ratings',
        pageTitle: 'real-estate-licensed-ratings',
        breadcrumb: [
          {
            to: { name: 'real-estate-licensed' },
            text: 'real-estates',
          },
          {
            text: 'real-estate-ratings',
            active: true,
          },
        ],
      },
      component: () => import('@/views/real-estate/ratings/details.vue'),
    },

    ///

    ////
    {
      path: '/real-estate-not-licensed/add',
      name: 'add-real-estate-not-licensed',
      meta: {
        resource: 'real-estate',
        pageTitle: 'add-real-estate-not-licensed',
        breadcrumb: [
          {
            to: { name: 'real-estate-not-licensed' },
            text: 'real-estates',
          },
          {
            text: 'real-estate-not-licensed',
            active: true,
          },
        ],
      },
      component: () => import('@/views/real-estate/form.vue'),
    },

    {
      path: '/real-estate-not-licensed/:resourceId/edit',
      name: 'edit-real-estate-not-licensed',
      meta: {
        resource: 'real-estate',
        pageTitle: 'edit-real-estate-not-licensed',
        breadcrumb: [
          {
            to: { name: 'real-estate-not-licensed' },
            text: 'real-estates',
          },
          {
            text: 'real-estate-not-licensed',
            active: true,
          },
        ],
      },
      component: () => import('@/views/real-estate/form.vue'),
    },
    {
      path: '/real-estate-not-licensed/:resourceId/show',
      name: 'details-real-estate-not-licensed',
      meta: {
        resource: 'real-estate',
        pageTitle: 'real-estate-not-licensed-details',
        breadcrumb: [
          {
            to: { name: 'real-estate-not-licensed' },
            text: 'real-estates',
          },
          {
            text: 'real-estate-details',
            active: true,
          },
        ],
      },
      component: () => import('@/views/real-estate/show.vue'),
    },
    {
      path: '/real-estate-not-licensed/:resourceId/images/show',
      name: 'show-images-real-estate-not-licensed',
      meta: {
        resource: 'real-estate',
        pageTitle: 'real-estate-not-licensed-images',
        breadcrumb: [
          {
            to: { name: 'real-estate-not-licensed' },
            text: 'real-estates',
          },
          {
            text: 'real-estate-images',
            active: true,
          },
        ],
      },
      component: () => import('@/views/real-estate/images/details.vue'),
    },
    {
      path: '/real-estate-not-licensed/:resourceId/ratings/:rateable_id',
      name: 'real-estate-not-licensed-ratings',
      meta: {
        resource: 'real-estate-ratings',
        pageTitle: 'real-estate-not-licensed-ratings',
        breadcrumb: [
          {
            to: { name: 'real-estate-not-licensed' },
            text: 'real-estates',
          },
          {
            text: 'real-estate-ratings',
            active: true,
          },
        ],
      },
      component: () => import('@/views/real-estate/ratings/details.vue'),
    },

    ///

    {
      path: '/real-estate/:resourceId/call-evaluation',
      name: 'real-estate-call-evaluation',
      meta: {
        resource: 'real-estate-call-evaluation',
        pageTitle: 'real-estate-call-evaluation',
        breadcrumb: [
          {
            to: { name: 'real-estate' },
            text: 'real-estates',
          },
          {
            text: 'real-estate-call-evaluation',
            active: true,
          },
        ],
      },
      component: () => import('@/views/real-estate/call-evaluation/details.vue'),
    },

    {
      path: '/real-estate/:resourceId/views',
      name: 'real-estate-views',
      meta: {
        resource: 'real-estate-views',
        pageTitle: 'real-estate-views',
        breadcrumb: [
          {
            to: { name: 'real-estate' },
            text: 'real-estates',
          },
          {
            text: 'real-estate-views',
            active: true,
          },
        ],
      },
      component: () => import('@/views/real-estate/views/details.vue'),
    },
    {
      path: '/real-estate-view',
      name: 'real-estate-view',
      component: () => import('@/views/real-estate/real-estate-view.vue'),
    },
    {
      path: '/real-estates/:slug/update-expiration-date',
      name: 'real-estates-update-expiration-date',
      component: () => import('@/views/statuses/modal.vue'),
    },

    {
      path: '/real-estate-requests-private',
      name: 'real-estate-requests-private',
      component: () => import('@/views/real-estate-requests-private/list.vue'),
    },
    {
      path: '/real-estate-requests-private/add',
      name: 'add-real-estate-requests-private',
      meta: {
        resource: 'real-estate-requests',
        pageTitle: 'add-real-estate-request-private',
        breadcrumb: [
          {
            to: { name: 'real-estate-requests-private' },
            text: 'real-estate-requests',
          },
          {
            text: 'real-estate-request',
            active: true,
          },
        ],
      },
      component: () => import('@/views/real-estate-requests/form.vue'),
    },

    {
      path: '/real-estate-requests-private/:resourceId/edit',
      name: 'edit-real-estate-requests-private',
      meta: {
        resource: 'real-estate-requests',
        pageTitle: 'edit-real-estate-request-private',
        breadcrumb: [
          {
            to: { name: 'real-estate-requests-private' },
            text: 'real-estate-requests',
          },
          {
            text: 'real-estate-request',
            active: true,
          },
        ],
      },
      component: () => import('@/views/real-estate-requests/form.vue'),
    },

    {
      path: '/real-estate-request-private/:resourceId/show',
      name: 'details-real-estate-request-private',
      meta: {
        resource: 'real-estate-request',
        pageTitle: 'real-estate-details-private',
        breadcrumb: [
          {
            to: { name: 'real-estate-request-private' },
            text: 'Real Estate Requests',
          },
          {
            text: 'real-estate-details',
            active: true,
          },
        ],
      },
      component: () => import('@/views/real-estate-requests/show.vue'),
    },

    ///

    {
      path: '/real-estate-requests',
      name: 'real-estate-requests',
      component: () => import('@/views/real-estate-requests/list.vue'),
    },
    {
      path: '/real-estate-requests/add',
      name: 'add-real-estate-request',
      meta: {
        resource: 'real-estate-requests',
        pageTitle: 'add-real-estate-request',
        breadcrumb: [
          {
            to: { name: 'real-estate-requests' },
            text: 'real-estate-requests',
          },
          {
            text: 'real-estate-request',
            active: true,
          },
        ],
      },
      component: () => import('@/views/real-estate-requests/form.vue'),
    },

    {
      path: '/real-estate-requests/:resourceId/edit',
      name: 'edit-real-estate-request',
      meta: {
        resource: 'real-estate-requests',
        pageTitle: 'edit-real-estate-request',
        breadcrumb: [
          {
            to: { name: 'real-estate-requests' },
            text: 'real-estate-requests',
          },
          {
            text: 'real-estate-request',
            active: true,
          },
        ],
      },
      component: () => import('@/views/real-estate-requests/form.vue'),
    },

    {
      path: '/real-estate-request/:resourceId/show',
      name: 'details-real-estate-request',
      meta: {
        resource: 'real-estate-request',
        pageTitle: 'real-estate-details',
        breadcrumb: [
          {
            to: { name: 'real-estate-request' },
            text: 'Real Estate Requests',
          },
          {
            text: 'real-estate-details',
            active: true,
          },
        ],
      },
      component: () => import('@/views/real-estate-requests/show.vue'),
    },


    {
      path: '/service',
      name: 'service',
      component: () => import('@/views/service/list.vue'),
    },
    {
      path: '/service/add',
      name: 'add-service',
      component: () => import('@/views/service/form.vue'),
      meta: {
        resource: 'service',
        pageTitle: 'Add Service',
        breadcrumb: [
          {
            to: { name: 'service' },
            text: 'service',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/service/:resourceId',
      name: 'edit-service',
      component: () => import('@/views/service/form.vue'),
      meta: {
        resource: 'service',
        pageTitle: 'Edit Service',
        breadcrumb: [
          {
            to: { name: 'service' },
            text: 'service',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },


    {
      path: '/real-estate-developer',
      name: 'real-estate-developer',
      component: () => import('@/views/developer/list.vue'),
    },
    {
      path: '/real-estate-developer/add',
      name: 'add-real-estate-developer',
      component: () => import('@/views/developer/form.vue'),
      meta: {
        resource: 'real-estate-developer',
        pageTitle: 'add-real-estate-developer',
        breadcrumb: [
          {
            to: { name: 'real-estate-developer' },
            text: 'real-estate-developer',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/real-estate-developer/:resourceId',
      name: 'edit-real-estate-developer',
      component: () => import('@/views/developer/form.vue'),
      meta: {
        resource: 'real-estate-developer',
        pageTitle: 'edit-real-estate-developer',
        breadcrumb: [
          {
            to: { name: 'real-estate-developer' },
            text: 'real-estate-developer',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },


    {
      path: '/opportunity-hub',
      name: 'opportunity-hub',
      component: () => import('@/views/opportunity-hub/list.vue'),
    },
    {
      path: '/opportunity-hub/add',
      name: 'add-opportunity-hub',
      component: () => import('@/views/opportunity-hub/form.vue'),
      meta: {
        resource: 'opportunity-hub',
        pageTitle: 'add-opportunity-hub',
        breadcrumb: [
          {
            to: { name: 'opportunity-hub' },
            text: 'opportunity-hub',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/opportunity-hub/:resourceId',
      name: 'edit-opportunity-hub',
      component: () => import('@/views/opportunity-hub/form.vue'),
      meta: {
        resource: 'opportunity-hub',
        pageTitle: 'edit-opportunity-hub',
        breadcrumb: [
          {
            to: { name: 'opportunity-hub' },
            text: 'opportunity-hub',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },


    {
      path: '/customer-opportunity-hub',
      name: 'customer-opportunity-hub',
      component: () => import('@/views/customer-opportunity-hub/list.vue'),
    },


    {
      path: '/service-customer-form',
      name: 'service-customer-form',
      component: () => import('@/views/service-customer-form/list.vue'),
    },

    {
      path: '/service-customer-form/:resourceId',
      name: 'edit-service-customer-form',
      component: () => import('@/views/service-customer-form/form.vue'),
      meta: {
        resource: 'service-customer-form',
        pageTitle: 'service-customer-form',
        breadcrumb: [
          {
            to: { name: 'service-customer-form' },
            text: 'service-customer-form',
          },
          {
            text: 'view',
            active: true,
          },
        ],
      },
    },

    {
      path: '/service-provider',
      name: 'service-provider',
      component: () => import('@/views/service-provider/list.vue'),
    },
    {
      path: '/service-provider/add',
      name: 'add-service-provider',
      component: () => import('@/views/service-provider/form.vue'),
      meta: {
        resource: 'service-provider',
        pageTitle: 'add-service-provider',
        breadcrumb: [
          {
            to: { name: 'service-provider' },
            text: 'service-provider',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/service-provider/:resourceId',
      name: 'edit-service-provider',
      component: () => import('@/views/service-provider/form.vue'),
      meta: {
        resource: 'service-provider',
        pageTitle: 'edit-service-provider',
        breadcrumb: [
          {
            to: { name: 'service-provider' },
            text: 'service-provider',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },

    {
      path: '/banner',
      name: 'banner',
      component: () => import('@/views/banner/list.vue'),
    },
    {
      path: '/banner/add',
      name: 'add-banner',
      component: () => import('@/views/banner/form.vue'),
      meta: {
        resource: 'banner',
        pageTitle: 'add-banner',
        breadcrumb: [
          {
            to: { name: 'banner' },
            text: 'banner',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/banner/:resourceId',
      name: 'edit-banner',
      component: () => import('@/views/banner/form.vue'),
      meta: {
        resource: 'banner',
        pageTitle: 'edit-banner',
        breadcrumb: [
          {
            to: { name: 'banner' },
            text: 'banner',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },

    {
      path: '/real-estate-developer-project',
      name: 'real-estate-developer-project',
      component: () => import('@/views/project/list.vue'),
    },
    {
      path: '/real-estate-developer-project/add',
      name: 'add-real-estate-developer-project',
      component: () => import('@/views/project/form.vue'),
      meta: {
        resource: 'real-estate-developer-project',
        pageTitle: 'add-real-estate-developer-project',
        breadcrumb: [
          {
            to: { name: 'real-estate-developer-project' },
            text: 'project',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/real-estate-developer-project/:resourceId',
      name: 'edit-real-estate-developer-project',
      component: () => import('@/views/project/form.vue'),
      meta: {
        resource: 'real-estate-developer-project',
        pageTitle: 'edit-real-estate-developer-project',
        breadcrumb: [
          {
            to: { name: 'real-estate-developer-project' },
            text: 'project',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },


    {
      path: '/dynamic-list',
      name: 'dynamic-list',
      component: () => import('@/views/dynamic-list/list.vue'),
    },

    {
      path: '/dynamic-list/:resourceId',
      name: 'edit-dynamic-list',
      component: () => import('@/views/dynamic-list/form.vue'),
      meta: {
        resource: 'dynamic-list',
        pageTitle: 'Edit Dynamic List',
        breadcrumb: [
          {
            to: { name: 'dynamic-list' },
            text: 'dynamic-list',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },


    {
      path: '/payment',
      name: 'payment',
      component: () => import('@/views/payment/list.vue'),
    },

    {
      path: '/payment-customer/:resourceId',
      name: 'payment-customer',
      component: () => import('@/views/payment/payment-customer.vue'),
    },

    // {
    //   path: '/payment/:resourceId',
    //   name: 'edit-payment',
    //   component: () => import('@/views/payment/form.vue'),
    //   meta: {
    //     resource: 'payment',
    //     pageTitle: 'Show payment',
    //     breadcrumb: [
    //       {
    //         to: { name: 'dynamic-list' },
    //         text: 'dynamic-list',
    //       },
    //       {
    //         text: 'Edit',
    //         active: true,
    //       },
    //     ],
    //   },
    // },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()
  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })

    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
