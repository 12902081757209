<template>
  <div>
    <b-card>
      <validation-observer
        ref='form'
        v-slot='{invalid}'
      >
        <b-card v-if='!isDataLoaded'>
          <div class='text-center text-success my-2'>
            <b-spinner class='align-middle' />
          </div>
        </b-card>
        <b-form
          v-else
          novalidate
          @submit.prevent='onSubmit'
        >
          <b-row>


            <b-col
              cols='12'
              xl='12'
              md='12'
            >
              <LocaleTabs
                :errors='formErrors'
                name='title'
              >
                <template
                  v-for='(i,k,idx) in languages'
                  :slot='`lang${idx}`'
                  slot-scope='slotScope'
                >
                  <ValidationProvider
                    :key='idx'
                    v-slot='{ errors }'
                    :class="slotScope.selectedTab!==idx?'hidden':''"
                    :vid='`title.${k}`'
                    :name="$t('general.field_lang', { field: $t('labels.title'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                    rules='required'
                  >
                    <b-form-group
                      :label="$t('general.field_lang', { field: $t('labels.title'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                    >
                      <b-form-input
                        v-model='form.title[k]'
                        :placeholder="$t('general.field_lang', { field: $t('labels.title'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                      />
                      <small class='text-danger'>{{ errors[0] }}</small>
                    </b-form-group>
                  </ValidationProvider>
                </template>
              </LocaleTabs>
            </b-col>


            <b-col cols='12'>
              <LocaleTabs
                :errors='formErrors'
                name='body'
              >
                <template
                  v-for='(i,k,idx) in languages'
                  :slot='`lang${idx}`'
                  slot-scope='slotScope'
                >
                  <ValidationProvider
                    :key='idx'
                    v-slot='{ errors }'
                    :class="slotScope.selectedTab!==idx?'hidden':''"
                    :vid='`body.${k}`'
                    :name="$t('general.field_lang', { field: $t('labels.body'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                    rules='required'
                  >
                    <b-form-group
                      :label="$t('general.field_lang', { field: $t('labels.body'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                    >
                      <b-form-textarea
                        v-model='form.body[k]'
                        :placeholder="$t('general.field_lang', { field: $t('labels.body'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                      />
                      <small class='text-danger'>{{ errors[0] }}</small>
                    </b-form-group>
                  </ValidationProvider>
                </template>
              </LocaleTabs>
            </b-col>

            <b-col
              cols='12'
              md='6'
            >
              <validation-provider
                v-slot='{ errors }'
                vid='packages'
                :name="$t('packages')"
              >
                <b-form-group
                  :label="$t('packages')"
                >
                  <v-select
                    v-model='form.packages'
                    :dir='$store.state.appConfig.layout.direction'
                    label='name'
                    multiple
                    :options='packages'
                    :reduce='item => item.id'
                    :placeholder="$t('select')"
                  >
                    <template #no-options>
                      {{ $t('No options') }}
                    </template>
                  </v-select>
                  <small class='text-danger'>{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              cols='12'
              md='6'
            >
              <validation-provider
                v-slot='{ errors }'
                vid='types'
                :name="$t('type')"
              >
                <b-form-group
                  :label="$t('type')"
                >
                  <v-select
                    v-model='type'
                    :dir='$store.state.appConfig.layout.direction'
                    label='name'
                    :options='types'
                    :reduce='item => item.id'
                    :placeholder="$t('select')"
                  >
                    <template #no-options>
                      {{ $t('No options') }}
                    </template>
                  </v-select>
                  <small class='text-danger'>{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>


            <b-col
              cols='12'
              md='6'
              v-if='type =="page"'
            >
              <validation-provider
                v-slot='{ errors }'
                vid='pages'
                :name="$t('page')"
              >
                <b-form-group
                  :label="$t('page')"
                >
                  <v-select
                    v-model='form.page'
                    :dir='$store.state.appConfig.layout.direction'
                    label='name'
                    :options='pages'
                    :reduce='item => item.id'
                    :placeholder="$t('select')"
                  >
                    <template #no-options>
                      {{ $t('No options') }}
                    </template>
                  </v-select>
                  <small class='text-danger'>{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              cols='12'
              md='6'
              v-if='type =="page" && show_page_list'
            >
              <validation-provider
                v-slot='{ errors }'
                vid='page_id'
                :name="$t('page_required_id')"
                rules='required'
              >
                <b-form-group
                  :label="$t('page_required_id')"
                >
                  <v-select
                    v-model='form.page_id'
                    :dir='$store.state.appConfig.layout.direction'
                    label='name'
                    :options='page_options'
                    :reduce='item => item.id'
                    :placeholder="$t('select')"
                  >
                    <template #no-options>
                      {{ $t('No options') }}
                    </template>
                  </v-select>
                  <small class='text-danger'>{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>


            <b-col
              cols='12'
              md='6'
              v-if='type =="link"'
            >
              <validation-provider
                v-slot='{ errors }'
                :name="$t('link')"
                vid='link'
                rules='required'
              >
                <b-form-group
                  :label="$t('link')"
                >
                  <b-form-input
                    v-model='form.link'
                    :placeholder="$t('link')"
                    autocomplete='off'
                    type='text'
                  />
                  <small class='text-danger'>{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>


            <b-col cols='12'>
              <ValidationProvider
                v-slot='{ errors }'
                vid='image'
                :name="$t('labels.image')"
              >
                <ImagePreview
                  v-model='image'
                  :label="$t('labels.image')"
                />
                <small class='text-danger'>{{ errors[0] }}</small>
              </ValidationProvider>
              <hr class='my-1'>
            </b-col>


            <!-- submit and reset -->
            <b-col cols='12 text-right'>
              <LoadingButton />
              <b-button
                type='reset'
                variant='outline-secondary'
              >
                {{ $t('labels.reset') }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>
<script>
import formMixin from '@/mixins/formMixin'
import { websiteMainUrl } from '@/services/url.service'
import _ from 'lodash'

export default {
  mixins: [formMixin],
  data() {
    return {
      image: null,
      packages: [],
      page_options: [],

      types: [{
        id: 'link',
        name: this.$t('link'),
      },
        {
          id: 'page',
          name: this.$t('page'),
        },
      ],
      type: null,
      show_page_list: false,


      pages: [
        {
          id: '/subscriptions',
          name: this.$t('packages'),
        },
        {
          id: '/investmentHubStories',
          name: this.$t('investment_hub_stories'),
        },
        {
          id: '/profile',
          name: this.$t('Profile'),
        },
        {
          id: '/projectDetails',
          name: this.$t('project_details'),
        },
        {
          id: '/developerDetails',
          name: this.$t('developer_details'),
        },
        {
          id: '/add_estate_options',
          name: this.$t('add_estate_options'),
        },
        {
          id: '/privateOffer',
          name: this.$t('private_offer'),
        },

        {
          id: '/request_estate_private',
          name: this.$t('request_estate_private'),
        },
        {
          id: '/request_estate',
          name: this.$t('request_estate'),
        },
      ],

      form: {
        page: null,
        title: {
          en: null,
          ar: null,
        },
        body: {
          en: null,
          ar: null,
        },
        image: null,
        link: null,
        packages: [],
        page_id: null,

      },

      isDataLoaded: false,
    }
  },
  created() {
    this.axios.get(`/model-list/packages-list`)
      .then(res => {
        this.packages = res.data.data
      })
    if (this.isEdit) {
      this.loadData()
    } else {
      this.isDataLoaded = true
    }
  },
  watch: {
    image(val) {
      if (val) {
        this.form.image = val
      } else {
        this.form.image = 'delete'
      }
    },

    type(val) {
      this.form.link = null
      this.form.page = null
    },
    'form.page': {
      handler(val) {
        this.show_page_list = false
        this.form.page_id = null

        if (val == '/projectDetails') {
          this.getPageOptions('projects')
        }
        if (val == '/developerDetails') {
          this.getPageOptions('developers')
        }
        if (val == '/investmentHubStories') {
          this.getPageOptions('opportunities')
        }

      },
    },
  },

  methods: {
    loadData() {
      this.axios.get(`/${this.resource}/${this.resourceId}`)
        .then(res => {
          const { data } = res.data
          this.form = {
            title: data?.title['ar'] ?? false ? data.title : { en: null, ar: null },
            body: data?.body['ar'] ?? false ? data.body : { en: null, ar: null },
            packages: data.packages,
          }
          this.image = data.image_path

          this.isDataLoaded = true
        })
    },
    getPageOptions(type) {
      this.show_page_list = true

      this.axios.get(`/model-list/${type}`)
        .then(res => {
          this.form.page_id = null
          this.page_options = res.data.data
        })
    },

  },

  loadFormData() {
    if (this.form.page == '/request_estate_private') {
      this.form.page = 'request_estate'
      this.form.page_id = 1
    }

    let $form = _.cloneDeep(this.form)

    $form = {
      ...$form,
    }



    return $form
  },

}
</script>
