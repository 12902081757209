<template>
  <div>
    <div>
      <MainInfoRealEstate
        :is-data-loaded='isDataLoaded'
        :keys='keys'
        :title="$t('property_information')"
      />
      <TableIndex
        :resource-name='resourceName'
        :singular-name='singularName'
        :filter-fields='{rateable_id : $route.params.rateable_id}'
      />

    </div>
  </div>
</template>

<script>
import MainInfoRealEstate from '@/components/MainInfoRealEstate.vue'

export default {
  components: {
    MainInfoRealEstate,
  },
  data() {
    return {
      resourceName: 'real-estate-rating',
      singularName: 'real-estate-rating',
      details: null,
      keys: null,
      isDataLoaded: false,
    }
  },
  created() {
    if (this.$route.params.resourceId) {
      this.axios.get(`/real-estate/${this.$route.params.resourceId}`)
        .then(res => {
          this.details = res.data.data
           this.keys = _.pick(this.details, [
            'title',
            'region',
            'city',
            'district',
            'the_sub_type_of_ad',
            'type',
            'advertiser_name',
            'advertiser_character',
            'advertiser_phone',
            'selling_price',
            'published_at',
            'status_value',
          ])

          this.isDataLoaded = true
        })
    }
  },
}
</script>
